import axios from "axios";
import Loading from "../Loaders/Loading";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import RoomIcon from "@mui/icons-material/Room";
import GiteIcon from "@mui/icons-material/Gite";
import HouseIcon from "@mui/icons-material/House";
import HotelIcon from "@mui/icons-material/Hotel";
import GavelIcon from "@mui/icons-material/Gavel";
import { Carousel } from "react-responsive-carousel";
import { API_URL, HEADERS, THEMES, ID_APPLICATION } from "../../constants";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import CelebrationIcon from "@mui/icons-material/Celebration";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import TheaterComedyIcon from "@mui/icons-material/TheaterComedy";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { UserContext } from "../../contexts/UserContext";
import NoPic from "../../assets/no-image.png";

const Slider = () => {
  const [carouselData, setCarouselData] = useState([]);
  const { lang, application } = useContext(UserContext);

  const getCarouselData = async () => {
    try {
      const { data } = await axios.get(
        `${API_URL}/appPoi/application/${ID_APPLICATION}/homePageFeatured`,
        HEADERS
      );

      if (data) {
        const newData = [
          {
            _id: "firstslide",
            img: application?.app_config.logo,
          },
          ...data.result,
        ];
        setCarouselData(newData);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getCarouselData();
  }, []);

  const iconToCategory = {
    FETE_ET_MANIFESTATION: <CelebrationIcon />,
    HEBERGEMENT_LOCATIF: <HouseIcon />,
    HOTELLERIE: <HotelIcon />,
    RESTAURATION: <RestaurantIcon />,
    ACTIVITE: <LocalActivityIcon />,
    HEBERGEMENT_COLLECTIF: <GiteIcon />,
    DEGUSTATION: <AgricultureIcon />,
    PATRIMOINE_CULTUREL: <TheaterComedyIcon />,
    STRUCTURE: <GavelIcon />,
    COMMERCE_ET_SERVICE: <ShoppingBasketIcon />,
    EQUIPEMENT: <LocationCityIcon />,
    SAVEURS: <GavelIcon />,
  };

  if (!carouselData) {
    return <Loading />;
  }

  return (
    <div className="my-0 w-full md:mt-6 md:mb-6">
      <Carousel
        dynamicHeight={false}
        infiniteLoop
        autoPlay
        swipeable
        interval={7000}
        transitionTime={700}
        showThumbs={false}
        thumbWidth={100}
        showStatus={false}
        renderArrowPrev={(handler) => {
          return (
            <div
              onClick={handler}
              className="absolute top-1/3 left-3 z-50 flex w-8 cursor-pointer text-white lg:left-0 lg:top-48 lg:text-RICHBLACK"
            >
              <ArrowBackIosIcon />
            </div>
          );
        }}
        renderArrowNext={(handler) => {
          return (
            <div
              onClick={handler}
              className="absolute top-1/3 right-0 z-50 flex w-8 cursor-pointer text-white lg:top-48 lg:right-0 lg:text-RICHBLACK"
            >
              <ArrowForwardIosIcon />
            </div>
          );
        }}
      >
        {carouselData.length &&
          carouselData.map((item) =>
            item._id === "firstslide" ? (
              <Box
                key="logo"
                sx={{
                  width: "100%",
                  height: 400,
                  display: "flex",
                  alignItems: "center",
                  "@media screen and (max-width: 600px)": {
                    alignItems: "center",
                    flexDirection: "column",
                  },
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: 400,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={
                      window.matchMedia("(max-width: 600px)").matches
                        ? {
                            display: "block",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            marginLeft: "auto",
                            marginRight: 0,
                            borderRadius: 0,
                            overflow: "hidden",
                            backgroundColor: "white",
                          }
                        : {
                            display: "block",
                            width: "50%",
                            height: "100%",
                            objectFit: "contain",
                            marginLeft: "auto",
                            marginRight: 52,
                            borderRadius: 8,
                            overflow: "hidden",
                            backgroundColor: "white",
                          }
                    }
                    src={application?.app_config.logo}
                    alt="Logo"
                  />
                </div>

                <Box
                  sx={{
                    "@media screen and (max-width: 600px)": {
                      left: 0,
                      bottom: 0,
                      width: "100%",
                      borderRadius: 0,
                      backgroundColor: THEMES.ALICEBLUE,
                      boxShadow: "none",
                    },
                    position: "absolute",
                    height: 150,
                    width: "46%",
                    backgroundColor: THEMES.BLUE,
                    left: 52,
                    borderRadius: 2,
                    boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.7)",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    p: 2,
                    color: "white",
                  }}
                >
                  <p>Bienvenue sur le site de</p>
                  <h2 className="text-md text-xl font-bold">
                    {application?.libelle}
                  </h2>
                  <p>{application?.localisation?.city_name}</p>
                </Box>
              </Box>
            ) : (
              <Box
                key={item?._id}
                sx={{
                  width: "100%",
                  height: 400,
                  display: "flex",
                  alignItems: "center",
                  "@media screen and (max-width: 600px)": {
                    alignItems: "center",
                    flexDirection: "column",
                  },
                }}
              >
                <Link
                  to={`${process.env.PUBLIC_URL}/poidetails/${item?._id}`}
                  style={{
                    width: "100%",
                    height: 400,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={item?.medias[0] ? item.medias[0].url : NoPic}
                    alt={
                      lang === "fr"
                        ? item?.categories[0].name.fr
                        : item?.categories[0].name.en
                    }
                    style={
                      window.matchMedia("(max-width: 600px)").matches
                        ? {
                            display: "block",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            marginLeft: "auto",
                            marginRight: 0,
                            borderRadius: 0,
                            overflow: "hidden",
                          }
                        : {
                            display: "block",
                            width: "50%",
                            height: "100%",
                            objectFit: "cover",
                            marginLeft: "auto",
                            marginRight: 52,
                            borderRadius: 8,
                            overflow: "hidden",
                          }
                    }
                  />
                  <Box
                    sx={{
                      "@media screen and (max-width: 600px)": {
                        left: 0,
                        bottom: 0,
                        width: "100%",
                        borderRadius: 0,
                        backgroundColor: THEMES.ALICEBLUE,
                        boxShadow: "none",
                      },
                      position: "absolute",
                      height: "fit-content",
                      width: "46%",
                      backgroundColor: THEMES.BLUE,
                      left: 52,
                      borderRadius: 2,
                      boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.7)",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      px: 2,
                    }}
                  >
                    <span className="mt-4 ml-8 flex w-full flex-row items-center justify-start gap-2 text-sm italic text-BLUE lg:text-GOLD">
                      <p
                        className="flex items-center gap-2"
                        // to={`${process.env.PUBLIC_URL}/categories/${item?.categories[0].category_id}`}
                      >
                        {iconToCategory[item?.categories[0].type]}{" "}
                        {lang === "fr"
                          ? item?.categories[0].name.fr
                          : item?.categories[0].name.en}
                      </p>
                    </span>
                    <h4 className="mb-8 max-w-full truncate text-center text-xl font-black text-RICHBLACK md:mb-3 md:mt-3 lg:text-ALICEBLUE">
                      {item.name}
                    </h4>
                    <span className="hidden text-ALICEBLUE md:flex">
                      <RoomIcon sx={{ color: "#F2F7FC" }} />
                      {item.city}
                    </span>
                    <p className="max-w-96 my-6 hidden whitespace-pre-wrap p-3 text-sm text-ALICEBLUE md:flex">
                      {lang === "fr"
                        ? item.short_description.fr
                        : item.short_description.en}
                    </p>
                  </Box>
                </Link>
              </Box>
            )
          )}
      </Carousel>
    </div>
  );
};

export default Slider;
