import axios from "axios";
import { Modal } from "@mui/material";
import trad from "../../lang/traduction";
import { API_URL, THEMES } from "../../constants";
import { useContext, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { useToasts } from "react-toast-notifications";
import ModalCreateWishlist from "./ModalCreateWishlist";
import { UserContext } from "../../contexts/UserContext";

const ModalAddToWishlist = ({ poiId, closeParentModal }) => {
  const { userWishlists, authHeaders, lang } = useContext(UserContext);
  const { addToast } = useToasts();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const addPoi = async (wishlist) => {
    try {
      const { data } = await axios.post(
        `${API_URL}/user/wishlist/${wishlist._id}/poi`,
        { id_poi: poiId },
        authHeaders
      );
      closeParentModal();
      addToast(trad[lang].wishlist.add, {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {userWishlists.length > 0 ? (
        <div className="absolute bottom-0 z-[9000] flex h-[30%] w-full flex-col items-center rounded-md bg-ALICEBLUE p-3 lg:top-1/2 lg:left-1/2 lg:h-fit lg:w-[400px] lg:-translate-x-1/2 lg:-translate-y-1/2">
          <span className="flex w-full flex-row">
            <h2 className="mb-5 text-center text-base font-semibold text-RICHBLACK">
              {trad[lang].wishlist.modalAddToWishlist}
            </h2>
          </span>
          <div className="flex h-full w-4/5 flex-col items-center border-b-[1px] border-BLUE">
            {userWishlists?.map((wishlist: any) => (
              <button
                key={wishlist._id}
                className="w-full border-t-[1px] border-BLUE py-4 px-4 text-BLUE hover:bg-PRIMARY hover:bg-opacity-25"
                onClick={() => addPoi(wishlist)}
              >
                {wishlist.title}
              </button>
            ))}
          </div>
        </div>
      ) : (
        <div className="absolute bottom-0 z-[9000] flex h-[30%] w-full flex-col items-center rounded-md bg-ALICEBLUE p-4 lg:top-1/2 lg:left-1/2 lg:h-fit lg:w-[400px] lg:-translate-x-1/2 lg:-translate-y-1/2">
          <h2 className="mb-5 text-base">{trad[lang].wishlist.noWishlist}</h2>
          <div className="flex h-full w-full flex-col items-center">
            <button
              className="mx-1 my-2 w-fit rounded-md bg-BLUE p-2 text-ALICEBLUE duration-300 hover:bg-PRIMARY hover:text-RICHBLACK"
              onClick={handleOpen}
            >
              {trad[lang].wishlist.createWishlist}
            </button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{ zIndex: 9000 }}
            >
              <ModalCreateWishlist handleClose={handleClose} />
            </Modal>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalAddToWishlist;
