import axios from "axios";
import { Box } from "@mui/system";
import "leaflet/dist/leaflet.css";
import PoiPopPup from "./PoiPopPup";
import CardInfoPoi from "./CardInfoPoi";
import trad from "../../lang/traduction";
import FilterCatMap from "./FilterCatMap";
import { getCat } from "../utils/CatColor";
import { IconButton } from "@mui/material";
import NoPic from "../../assets/no-image.png";
import NoPicFr from "../../assets/pas-image.png";
import { MapContext } from "../../contexts/MapContext";
import { UserContext } from "../../contexts/UserContext";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import React, { useContext, useEffect, useRef, useState } from "react";
import { API_URL, HEADERS, ID_APPLICATION, THEMES } from "../../constants";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  GeoJSON,
  useMap,
} from "react-leaflet";
import { latLngBounds } from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";

declare let L: any;

const MapComponent: React.FC = () => {
  const { lang, application } = useContext(UserContext);
  const [dataMapPoi, setDataMapPoi] = useState<any>({});
  const [categories, setCategories] = useState<any>([]);
  const [displayCourse, setDisplayCourse] = useState<boolean>(false);
  const [selectedCategorieId, setSelectedCategorieId] = useState<any>([]);
  const {
    open,
    setOpen,
    selectedPoi,
    setSelectedPoi,
    handleShowCardInfoPoi,
  } = useContext(MapContext);

  useEffect(() => {
    getPoi();
  }, [selectedCategorieId]);

  // GET ALL POI
  const getPoi = async () => {
    try {
      const { data } = await axios.get(
        `${API_URL}/appPoi/poi?idApplication=${ID_APPLICATION}${
          selectedCategorieId ? `&categoryId=${selectedCategorieId}` : null
        }`,
        HEADERS
      );
      setDataMapPoi(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getPoi();
  }, []);

  const _map: any = useRef();

  useEffect(() => {
    if (application) {
      _map.current.flyTo([
        application.localisation.coords.latitude,
        application.localisation.coords.longitude,
      ]);
    }
  }, [application]);

  const centerTo = () => {
    if (selectedPoi) {
      _map.current?.flyTo([selectedPoi?.latitude, selectedPoi?.longitude]);
    }
  };

  const handleDisplayCourse = () => {
    setSelectedPoi(!selectedPoi);
    setDisplayCourse(!displayCourse);
  };

  useEffect(() => {
    if (selectedPoi) {
      centerTo();
    }
  }, [selectedPoi]);

  useEffect(() => {
    if (dataMapPoi.result && dataMapPoi.result.length && _map.current) {
      const markerBounds = latLngBounds([]);
      dataMapPoi.result.forEach((marker) => {
        markerBounds.extend([marker.latitude, marker.longitude]);
      });
      _map.current.fitBounds(markerBounds, { padding: [10, 10] });
    }
  }, [dataMapPoi, _map]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      style={{
        paddingTop: 64,
        height: "100vh",
        width: "100%",
        position: "relative",
        zIndex: 50,
      }}
    >
      <MapContainer
        ref={(ref) => {
          _map.current = ref;
          if (selectedPoi?.latitude && selectedPoi?.longitude) {
            centerTo();
          }
        }}
        center={[43.95227, 4.79198]}
        zoom={15}
        scrollWheelZoom={true}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

        <MarkerClusterGroup>
          {/* MARKER */}
          {displayCourse === false &&
            dataMapPoi?.result?.map((poi: any) => (
              <div key={poi._id}>
                <Marker
                  icon={
                    new L.divIcon({
                      iconSize: poi === selectedPoi ? [30, 30] : [25, 25],
                      iconAnchor: poi === selectedPoi ? [15, 15] : [12.5, 12.5],
                      popupAnchor: [0, -10],
                      html: getCat(
                        poi.categories[0].type,
                        poi === selectedPoi ? 55 : 45
                      ).icon,
                      className: `leaflet-div-icon`,
                    })
                  }
                  position={[poi?.latitude, poi?.longitude]}
                  eventHandlers={{
                    click: () => handleShowCardInfoPoi(poi),
                  }}
                >
                  <Popup>
                    <PoiPopPup name={poi?.name} />
                  </Popup>
                </Marker>
              </div>
            ))}

          {/* POLYLINE */}
          {selectedPoi?.geoJSON?.type === "MultiLineString" &&
            displayCourse === true && (
              <div>
                <GeoJSON
                  data={selectedPoi?.geoJSON}
                  pathOptions={{ color: "#FF0033", weight: 6 }}
                />
                <Marker
                  icon={
                    new L.divIcon({
                      iconSize: [30, 30],
                      iconAnchor: [15, 15],
                      popupAnchor: [0, -10],
                      html: getCat(selectedPoi.categories[0].type, 55).icon,
                      className: `leaflet-div-icon`,
                    })
                  }
                  position={[selectedPoi?.latitude, selectedPoi?.longitude]}
                >
                  <Popup>
                    <PoiPopPup name={selectedPoi?.name} />
                  </Popup>
                </Marker>
              </div>
            )}
        </MarkerClusterGroup>
      </MapContainer>

      {/* FILTRE */}
      <FilterCatMap
        categories={categories}
        setCategories={setCategories}
        selectedCategorieId={selectedCategorieId}
        setSelectedCategorieId={setSelectedCategorieId}
      />

      {/* CARTE INFO */}
      {open && (
        <CardInfoPoi
          centerTo={centerTo}
          setDisplayCourse={setDisplayCourse}
          setOpen={setOpen}
          id={selectedPoi?._id}
          name={selectedPoi?.name}
          categories={selectedPoi?.categories}
          image={
            lang === "fr"
              ? selectedPoi?.medias[0]
                ? selectedPoi.medias[0].url || selectedPoi.medias[0].url_large
                : NoPicFr
              : selectedPoi?.medias[0]
              ? selectedPoi.medias[0].url || selectedPoi.medias[0].url_large
              : NoPic
          }
          description={
            selectedPoi?.short_description || trad[lang].maps.noDescription
          }
          email={selectedPoi?.email || null}
          address={selectedPoi?.address}
          socialmedia={selectedPoi?.website || null}
          phone={selectedPoi?.tel || null}
          displayCourse={
            selectedPoi?.geoJSON?.type === "MultiLineString" && (
              <Box className="flex flex-col items-center justify-center">
                <Box className="mb-1 flex h-[40px] w-[40px] flex-col items-center justify-center rounded-full border-2 border-BLUE">
                  {displayCourse === false ? (
                    <IconButton onClick={() => setDisplayCourse(true)}>
                      <VisibilityIcon
                        sx={{ fontSize: 30, color: THEMES.BLUE }}
                      />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => setDisplayCourse(false)}>
                      <VisibilityOffIcon
                        sx={{ fontSize: 30, color: THEMES.BLUE }}
                      />
                    </IconButton>
                  )}
                </Box>
                <p className="text-center text-xs">
                  {trad[lang].maps.displayCourse}
                </p>
              </Box>
            )
          }
        />
      )}
    </Box>
  );
};

export default MapComponent;
