import Map from "../pages/Map";
import Home from "../pages/Home";
import Results from "../pages/Results";
import Page404 from "../pages/Page404";
import Wishlist from "../pages/Wishlist";
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import Categories from "../pages/Categories";
import Challenges from "../pages/Challenges";
import PoiDetails from "../pages/PoiDetails";
import UserProfile from "../pages/UserProfile";
import NavBar from "../components/Navigation/NavBar";
import ForgotPassword from "../pages/auth/ForgotPassword";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ChallengeDetails from "../pages/ChallengeDetails";
import SurveyPage from "../pages/SurveyPage";

export const PublicRoutes = () => {
  return (
    <BrowserRouter>
      {/* <NavBar /> */}

      <Routes>
        <Route path={`${process.env.PUBLIC_URL}/`} element={<Home />} />
        <Route path={`${process.env.PUBLIC_URL}/map`} element={<Map />} />
        <Route
          path={`${process.env.PUBLIC_URL}/sign-in`}
          element={<SignIn />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/sign-up`}
          element={<SignUp />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/results`}
          element={<Results />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/user`}
          element={<UserProfile />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/challenges`}
          element={<Challenges />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/challenges/:id`}
          element={<ChallengeDetails />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/poidetails/:id`}
          element={<PoiDetails />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/categories/:id`}
          element={<Categories />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/user/wishlist/:id`}
          element={<Wishlist />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/forgot-password`}
          element={<ForgotPassword />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/survey`}
          element={<SurveyPage />}
        />
        <Route path={`${process.env.PUBLIC_URL}/*`} element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
};
