import trad from "../../lang/traduction";
import Loader from "../../assets/Rolling-1s-16px.png";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";

function SmallLoading() {
  const { lang } = useContext(UserContext);

  return (
    <div className="flex flex-row items-center justify-center gap-3">
      <img className="animate-spin" alt="Loader" src={Loader} />
      <span className="font-bold text-BLUE">{trad[lang].loading.title}</span>
    </div>
  );
}

export default SmallLoading;
