import axios from "axios";
import "leaflet/dist/leaflet.css";
import trad from "../lang/traduction";
import Loading from "../components/Loaders/Loading";
import { UserContext } from "../contexts/UserContext";
import { useContext, useEffect, useRef, useState } from "react";
import { API_URL, HEADERS, ID_APPLICATION } from "../constants";
import { MapContainer, TileLayer, Polyline } from "react-leaflet";
import Divider from "../components/Divider";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

function Challenges() {
  const [challenges, setChallenges] = useState([]);
  const { lang } = useContext(UserContext);
  const [loading, setLoading] = useState(true);

  const getChallenges = async () => {
    try {
      const { data } = await axios.get(
        `${API_URL}/sport/application/${ID_APPLICATION}/challenge`,
        HEADERS
      );
      if (data) {
        setChallenges(data);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getChallenges();
  }, []);

  const _map: any = useRef();

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="flex w-full flex-col items-center pb-16">
      <h3 className="my-8 mx-4 text-center text-3xl font-bold text-RICHBLACK">
        {trad[lang].challenges.title}
      </h3>
      <div className="flex w-full flex-col items-center gap-8 p-2 lg:flex-row lg:flex-wrap lg:justify-center lg:p-4">
        {challenges.map((item) => (
          <Link
            key={item.idChallenge}
            to={`${process.env.PUBLIC_URL}/challenges/${item.idChallenge}`}
            className="w-full lg:w-fit"
          >
            <div className="flex w-full flex-col items-center rounded-md border border-BLUE bg-PRIMARY bg-opacity-40 p-4 shadow-xl duration-200 hover:shadow-2xl lg:my-4 lg:w-[400px] lg:flex-col">
              <p className="mb-4 w-full flex-wrap justify-center overflow-hidden text-center font-semibold text-RICHBLACK lg:w-[400px]">
                {item.libelle}
              </p>
              <div className="flex w-full flex-row items-center lg:flex-col">
                <div className="h-32 w-32 border-2 border-BLUE lg:h-52 lg:w-80">
                  <MapContainer
                    ref={(ref) => {
                      _map.current = ref;
                    }}
                    center={[
                      item?.gpxPositions
                        .map((item) => parseFloat(item.latitude))
                        .reduce((p, c) => c + p, 0) / item?.gpxPositions.length,
                      item?.gpxPositions
                        .map((item) => parseFloat(item.longitude))
                        .reduce((p, c) => c + p, 0) / item?.gpxPositions.length,
                    ]}
                    zoom={11}
                    scrollWheelZoom={false}
                    zoomControl={false}
                    dragging={false}
                  >
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    <Polyline
                      pathOptions={{ color: item.couleur, opacity: 1 }}
                      positions={item?.gpxPositions.map((item) => [
                        item.latitude,
                        item.longitude,
                      ])}
                    />
                  </MapContainer>
                </div>
                <div className="flex w-2/3 flex-col items-center">
                  {item.dateDebut !== null ? (
                    <div className="my-2 flex flex-row justify-center">
                      <span className="ml-3 flex flex-row gap-1">
                        <p className="text-xs font-semibold lg:text-base">
                          {trad[lang].challenges.start}:{" "}
                        </p>
                        <p className="text-xs italic lg:text-base">
                          {dayjs(item.dateDebut).format("DD/MM/YYYY")}
                        </p>
                      </span>
                      <span className="ml-3 flex flex-row gap-1">
                        <p className="text-xs font-semibold lg:text-base">
                          {trad[lang].challenges.end}:{" "}
                        </p>
                        <p className="text-xs italic lg:text-base">
                          {dayjs(item.dateFin).format("DD/MM/YYYY")}
                        </p>
                      </span>
                    </div>
                  ) : (
                    <p className="my-2 text-xs lg:text-base">
                      {trad[lang].challenges.noDate}
                    </p>
                  )}

                  <div className="my-2 flex flex-row justify-center lg:w-80">
                    <span>
                      <p className="text-xs lg:text-base">
                        {item.distanceKm}km - {Math.round(item.denivelePositif)}
                        m D+ / {Math.round(item.deniveleNegatif)}m D-
                      </p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Challenges;
