import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";
import { Link, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { API_URL } from "../../constants";
import { UserContext } from "../../contexts/UserContext";
import axios from "axios";
import trad from "../../lang/traduction";
import { useToasts } from "react-toast-notifications";

const SignInForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();
  const { setToken, authHeaders, lang } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);
  //@ts-ignore
  const navigate = useNavigate();

  /*
   * !SECTION
   * SECTION ASYNC FUNCTIONS
   */
  const onSubmit = async () => {
    try {
      const formData: any = {
        username: username,
        password: password,
        remember: remember,
      };
      setLoading(true);
      const { data } = await axios.post(
        `${API_URL}/auth/login`,
        formData,
        authHeaders
      );
      setToken(data.token);
      navigate(`${process.env.PUBLIC_URL}/`);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        addToast(trad[lang].connection.invalid, {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        addToast(trad[lang].connection.errorConnection, {
          appearance: "error",
          autoDismiss: true,
        });
      }
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const validate = () => {
    // return usernameValidator.validate(username) && password.length > 4;
    return username.length > 1 && password.length > 2;
  };

  const handleKeyPress = (event: any) => {
    if (validate()) {
      if (event.key === "Enter") {
        onSubmit();
      }
    }
  };

  return (
    <>
      <div className="flex min-h-full w-full">
        <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-12">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                {trad[lang].connection.message}
              </h2>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <form className="space-y-6">
                  <div>
                    <label
                      htmlFor="username"
                      className="block text-sm font-medium text-RICHBLACK"
                    >
                      {trad[lang].connection.login}
                    </label>
                    <div className="mt-1 flex flex-row gap-3">
                      <input
                        id="username"
                        name="username"
                        type="text"
                        autoComplete="username"
                        required
                        onChange={(e) => setUsername(e.target.value)}
                        onKeyPress={(e) => handleKeyPress(e)}
                        className="block w-full appearance-none rounded-md border border-SILVER px-3 py-2 placeholder-RICHBLACK shadow-sm focus:border-TERTIARY focus:outline-none focus:ring-TERTIARY sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-RICHBLACK"
                    >
                      {trad[lang].connection.labelPassword}
                    </label>
                    <div className="relative mt-1 flex flex-row gap-3">
                      <input
                        id="password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        autoComplete="current-password"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyPress={(e) => handleKeyPress(e)}
                        className="block w-full appearance-none rounded-md border border-SILVER px-3 py-2 placeholder-RICHBLACK shadow-sm focus:border-TERTIARY focus:outline-none focus:ring-TERTIARY sm:text-sm"
                      />
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute right-2 top-1/2 inline-flex -translate-y-1/2 items-center rounded-full bg-ALICEBLUE p-1 text-ALICEBLUE"
                      >
                        {showPassword ? (
                          <EyeIcon className="h-5 w-5 text-TERTIARY" />
                        ) : (
                          <EyeOffIcon className="h-5 w-5 text-TERTIARY" />
                        )}
                      </button>
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 rounded border-SILVER text-TERTIARY focus:ring-TERTIARY"
                        onChange={(e) => setRemember(!remember)}
                      />
                      <label
                        htmlFor="remember-me"
                        className="ml-2 block text-sm text-gray-900"
                      >
                        {trad[lang].connection.rememberMe}
                      </label>
                    </div>

                    <div className="text-sm">
                      <Link
                        to={`${process.env.PUBLIC_URL}/forgot-password`}
                        className="font-medium text-TERTIARY hover:text-TERTIARY"
                      >
                        {trad[lang].connection.forgotPassword}
                      </Link>
                    </div>
                  </div>

                  <div>
                    <button
                      type="button"
                      onClick={onSubmit}
                      disabled={!validate()}
                      className="flex w-full justify-center rounded-md border border-transparent bg-BLUE py-2 px-4 text-sm font-medium text-ALICEBLUE shadow-sm hover:bg-PRIMARY focus:outline-none focus:ring-2 focus:ring-TERTIARY focus:ring-offset-2"
                    >
                      {trad[lang].connection.title}
                    </button>
                  </div>
                  <Link
                    className="text-xs text-BLUE hover:underline"
                    to={`${process.env.PUBLIC_URL}/sign-up`}
                  >
                    {trad[lang].connection.noAccount}
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1609861517208-e5b7b4cd4b87?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1935&q=80"
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default SignInForm;
