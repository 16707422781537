import { createContext, useEffect, useRef, useState } from "react";

export const MapContext: any = createContext(null);

const MapProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [selectedPoi, setSelectedPoi] = useState<any>();

  // OUVRIR LA CARTE ET PASSER SON POI
  const handleShowCardInfoPoi = (poi) => {
    setOpen(true);
    setSelectedPoi(poi);
  };

  // CENTRER LA MAP SUR LE POI SELECTIONNÉ

  return (
    <MapContext.Provider
      value={{
        open,
        setOpen,
        selectedPoi,
        setSelectedPoi,
        handleShowCardInfoPoi,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export default MapProvider;
