import React from "react";
import Survey from "../components/Survey/Survey";

const SurveyPage = () => {
  return (
    <div className="w-full py-20 sm:px-4 lg:px-36">
      <div className="mx-auto rounded-md bg-white p-4 shadow-md shadow-gray-400 md:w-[800px]">
        <Survey />
      </div>
    </div>
  );
};

export default SurveyPage;
