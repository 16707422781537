import axios from "axios";
import SmallLoading from "./Loaders/SmallLoading";
import { API_URL, HEADERS } from "../constants";
import React, { useContext, useEffect, useState } from "react";
import { iconSensor, valueSensor, colorSensor } from "../hooks/useSensor";
import { UserContext } from "../contexts/UserContext";

function Sensors({ id }) {
  const [sensors, setSensors] = useState([]);
  const { lang } = useContext(UserContext);

  const [loading, setLoading] = useState(true);

  const getSensorData = async () => {
    try {
      const { data } = await axios.get(
        `https://apict-preprod.dag-system.com/appPoi/sensor/?idPoi=${id}&last=true`,
        HEADERS
      );

      if (data) {
        setSensors(data.result[0].sensorsInfos);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSensorData();
    const interval = setInterval(() => getSensorData(), 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="flex flex-row items-center rounded-md border-2 border-BLUE p-2">
      {loading ? (
        <SmallLoading />
      ) : sensors.length > 0 ? (
        <div className="flex flex-row items-center gap-4">
          {sensors.map((sensor, index) => {
            return (
              <div key={index} className="flex flex-row items-center">
                <span style={{ color: colorSensor(sensor) }}>
                  {iconSensor(sensor)}
                </span>
                <p style={{ color: colorSensor(sensor) }}>
                  {valueSensor(sensor, lang)}
                </p>
              </div>
            );
          })}
        </div>
      ) : (
        "Pas de données"
      )}
    </div>
  );
}

export default Sensors;
