import axios from "axios";
import NoPic from "../assets/no-image.png";
import CardPoi from "../components/CardPoi";
import NoPicFr from "../assets/pas-image.png";
import { UserContext } from "../contexts/UserContext";
import NewSlider from "../components/Sliders/NewSlider";
import { useContext, useEffect, useState } from "react";
import { API_URL, ID_APPLICATION, HEADERS } from "../constants";

function Nearby({ latitude, longitude }) {
  const [nearbyPoi, setNearbyPoi] = useState<any>([]);
  const { lang } = useContext(UserContext);

  const getNearbyPoi = async () => {
    try {
      const { data } = await axios.get(
        `${API_URL}/appPoi/poi?idApplication=${ID_APPLICATION}&pageNumber=1&numberPerPage=6&nearby={"latitude":${latitude}, "longitude":${longitude}}`,
        HEADERS
      );
      if (data) {
        data.result.shift();
        setNearbyPoi(data.result);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getNearbyPoi();
  }, []);

  return (
    <div className="flex w-full justify-center">
      <NewSlider
        sliderId={undefined}
        content={nearbyPoi.map((item) => {
          return (
            <div className="item" key={item._id}>
              <CardPoi
                image={
                  lang === "fr"
                    ? item?.medias[0]
                      ? item.medias[0].url
                      : NoPicFr
                    : item?.medias[0]
                    ? item.medias[0].url
                    : NoPic
                }
                name={item.name}
                target={`${process.env.PUBLIC_URL}/poidetails/${item._id}`}
                idPoi={item._id}
                alt={item.name}
              />
            </div>
          );
        })}
      />
    </div>
  );
}

export default Nearby;
