import { title } from "process";
import axios from "axios";
import jwt_decode from "jwt-decode";
import trad from "../lang/traduction";
import { App } from "../types/application";
import { Favorites, Poi } from "../types/poi";
import { useToasts } from "react-toast-notifications";
import { createContext, useEffect, useState } from "react";
import { API_URL, APP_ID, APP_PASSWORD, ID_APPLICATION } from "../constants";

export const UserContext: any = createContext(null);

const UserProvider = ({ children }) => {
  const { addToast } = useToasts();
  const initialUser: any =
    JSON.parse(localStorage.getItem("user") as any) || [];
  const initialToken: string =
    JSON.parse(localStorage.getItem("token") as string) || "";
  const initialUserToken: any =
    JSON.parse(localStorage.getItem("userToken") as any) || [];
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(initialUser);
  const [token, setToken] = useState(initialToken);
  const [searchKeywords, setSearchKeywords] = useState("");
  const [favorites, setFavorites] = useState<Poi[] | []>([]);
  const [wishlists, setWishlists] = useState<any>([]);
  const [userWishlists, setUserWishlists] = useState<any>([]);
  const [userToken, setUserToken] = useState(initialUserToken);
  const [application, setApplication] = useState<App | App[]>();
  const [lang, setLang] = useState<string>("fr");

  /* Headers contenant le token JWT */
  const authHeaders = {
    headers: {
      authorization: `Bearer ${token}`,
      appId: APP_ID,
      appPassword: APP_PASSWORD,
    },
  };

  const getApplication = async () => {
    try {
      const { data } = await axios.get(
        `${API_URL}/appPoi/application/${ID_APPLICATION}`,
        authHeaders
      );
      setApplication(data.result);
      setLoading(false);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getApplication();
  }, [user, loading]);

  useEffect(() => {
    if (token) {
      const decodedToken: any = jwt_decode(token);
      localStorage.setItem("token", JSON.stringify(token));
      localStorage.setItem("user", JSON.stringify(decodedToken.user));
      setUserToken(decodedToken);
      setUser(decodedToken.user);
    }
  }, [token]);

  // RECUPERER LES POIS FAVORIS

  const getFavorites = async () => {
    if (user.IDApplication && user.TypeCompte !== 100)
      try {
        const { data } = await axios.get(
          `${API_URL}/user/favorite`,
          authHeaders
        );
        if (data) {
          setFavorites(data);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
  };

  const toggleFavorite = async (idPoi: any) => {
    const idCompare = favorites.find((fav: any) => fav._id === idPoi);
    if (idCompare) {
      const { data } = await axios.delete(
        `${API_URL}/user/favorite/poi/${idPoi}`,
        authHeaders
      );
      if (data) {
        setFavorites(data);
        addToast(trad[lang].favorites.remove, {
          appearance: "warning",
          autoDismiss: true,
        });
      }
    } else {
      const { data } = await axios.post(
        `${API_URL}/user/favorite`,
        {
          idPoi: idPoi,
        },
        authHeaders
      );
      if (data) {
        setFavorites(data);
        addToast(trad[lang].favorites.add, {
          appearance: "success",
          autoDismiss: true,
        });
      }
    }
  };

  // FAVORITE STATE //
  const isFavorite = (idPoi) => {
    if (favorites !== undefined) {
      return favorites.find((fav: any) => fav._id === idPoi);
    }
    return false;
  };

  // POST UNE WISHLIST //
  const postWishlist = async () => {
    try {
      if (!user.IDApplication) {
        return;
      }

      const titleInput = wishlists.title;
      const poiListUser = wishlists.poiList;
      const { data } = await axios.post(
        `${API_URL}/user/wishlist`,
        {
          title: titleInput,
          idUtilisateur: user.IDUtilisateur,
          poiList: poiListUser,
        },
        authHeaders
      );

      setWishlists(data);
      addToast(trad[lang].wishlist.success, {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  // RECUPERER LES WISHLISTS //
  const getWishlists = async () => {
    try {
      const { data } = await axios.get(`${API_URL}/user/wishlist`, authHeaders);
      if (data) {
        setUserWishlists(data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user.length) {
      getFavorites();
      getWishlists();
    }
  }, [user, loading]);

  const storeLang = async (value) => {
    try {
      await localStorage.setItem("lang", value);
      setLang(value);
    } catch (error) {
      console.error(error);
    }
  };

  // Récupère la langue stocker dans l'async storage
  useEffect(() => {
    const getLang = async () => {
      try {
        const storedLang = await localStorage.getItem("lang");
        storedLang && setLang(storedLang);
      } catch (error) {
        console.error(error);
      }
    };
    getLang();
  }, []);

  return (
    <UserContext.Provider
      value={{
        setUserWishlists,
        userWishlists,
        postWishlist,
        wishlists,
        setWishlists,
        getApplication,
        token,
        setToken,
        userToken,
        setUserToken,
        user,
        setUser,
        authHeaders,
        loading,
        application,
        setApplication,
        setLoading,
        searchKeywords,
        setSearchKeywords,
        favorites,
        toggleFavorite,
        isFavorite,
        getWishlists,
        lang,
        storeLang,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
