import MapComponent from "../components/Map/MapComponent";

const Map = () => {
  return (
    <div className="h-[calc(100vh-64px)]">
      <MapComponent />
    </div>
  );
};

export default Map;
