import axios from "axios";
import { Poi } from "../types/poi";
import trad from "../lang/traduction";
import { Modal } from "@mui/material";
import Nearby from "../components/Nearby";
import NoPic from "../assets/no-image.png";
import Jetcode from "../components/Jetcode";
import Divider from "../components/Divider";
import Sensors from "../components/Sensors";
import NoPicFr from "../assets/pas-image.png";
import MapIcon from "@mui/icons-material/Map";
import { API_URL, HEADERS } from "../constants";
import HomeIcon from "@mui/icons-material/Home";
import GiteIcon from "@mui/icons-material/Gite";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import HouseIcon from "@mui/icons-material/House";
import HotelIcon from "@mui/icons-material/Hotel";
import GavelIcon from "@mui/icons-material/Gavel";
import Loading from "../components/Loaders/Loading";
import { MapContext } from "../contexts/MapContext";
import { Carousel } from "react-responsive-carousel";
import { UserContext } from "../contexts/UserContext";
import LanguageIcon from "@mui/icons-material/Language";
import FacebookIcon from "@mui/icons-material/Facebook";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import DescriptionIcon from "@mui/icons-material/Description";
import CelebrationIcon from "@mui/icons-material/Celebration";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import React, { useContext, useEffect, useState, useId } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import TheaterComedyIcon from "@mui/icons-material/TheaterComedy";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import ModalAddToWishlist from "../components/Wishlist/ModalAddToWishlist";

const iconToCategory = {
  FETE_ET_MANIFESTATION: <CelebrationIcon />,
  HEBERGEMENT_LOCATIF: <HouseIcon />,
  HOTELLERIE: <HotelIcon />,
  RESTAURATION: <RestaurantIcon />,
  ACTIVITE: <LocalActivityIcon />,
  HEBERGEMENT_COLLECTIF: <GiteIcon />,
  DEGUSTATION: <AgricultureIcon />,
  PATRIMOINE_CULTUREL: <TheaterComedyIcon />,
  STRUCTURE: <GavelIcon />,
  COMMERCE_ET_SERVICE: <ShoppingBasketIcon />,
  EQUIPEMENT: <LocationCityIcon />,
};

const PoiDetails = () => {
  const { id } = useParams();
  const randomId = useId();
  const navigate = useNavigate();
  const [poi, setPoi] = useState<Poi>(null);
  const handleOpenModal = () => setOpen(true);
  const [dropDown, setDropDown] = useState(false);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const handleCloseModal = () => setOpen(false);
  const { handleShowCardInfoPoi } = useContext(MapContext);
  const { lang, toggleFavorite, isFavorite } = useContext(UserContext);

  const getPoi = async () => {
    try {
      const { data } = await axios.get(`${API_URL}/appPoi/poi/${id}`, HEADERS);
      if (data) {
        setPoi(data.result);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPoi();
  }, [id]);

  const handleChange = (e) => {
    e.stopPropagation();
    toggleFavorite(poi._id);
  };

  const seeOnMap = (e) => {
    handleShowCardInfoPoi(e);
    navigate(`${process.env.PUBLIC_URL}/map`);
  };
  const handleDropDown = () => {
    setDropDown(!dropDown);
  };

  if (loading) {
    return <Loading />;
  }

  if (!loading && !poi?.name) {
    return <div>{trad[lang].poiDetails.noInformation}</div>;
  }

  return (
    <div className="flex w-full flex-col items-center lg:px-24">
      <h3 className="my-10 w-4/5 text-center text-2xl font-bold text-RICHBLACK md:w-full">
        {poi.name}
      </h3>
      <div className="mb-8 flex w-full flex-col items-center justify-center gap-12 lg:flex-row">
        {poi.medias.length > 1 ? (
          <Carousel thumbWidth={80} showStatus={false}>
            {poi.medias.map((media, index) => (
              <div
                key={index}
                className="flex h-[400px] items-center justify-center rounded-md"
              >
                <img
                  src={media.url}
                  alt={media.description?.fr}
                  className="h-full w-full rounded-md object-contain lg:w-1/3"
                />
              </div>
            ))}
          </Carousel>
        ) : (
          <img
            src={
              lang === "fr"
                ? poi?.medias[0]
                  ? poi.medias[0].url
                  : NoPicFr
                : poi?.medias[0]
                ? poi.medias[0].url
                : NoPic
            }
            alt={poi.name}
            className="h-full w-full object-cover lg:w-1/3 lg:rounded-md"
          />
        )}

        <div className="w-full lg:w-2/3">
          <div className="ml-3 flex flex-col gap-4 md:flex-row md:justify-end">
            <Link
              to={`${process.env.PUBLIC_URL}/categories/${poi.categories[0].category_id}`}
            >
              <h3 className="flex w-fit flex-row items-center rounded-md border-2 border-BLUE p-2 text-base font-semibold leading-6 text-BLUE duration-300 hover:bg-SILVER hover:bg-opacity-25">
                <span className="mr-2">
                  {iconToCategory[poi.categories[0].type]}
                </span>
                {lang === "fr"
                  ? poi.categories[0].name.fr
                  : poi.categories[0].name.en}
              </h3>
            </Link>
            <span className="relative z-0 inline-flex rounded-md shadow-sm">
              <button
                type="button"
                onClick={(e) => handleChange(e)}
                className="relative inline-flex items-center rounded-l-md border border-BLUE bg-ALICEBLUE px-4 py-2 text-sm font-semibold text-WARNING duration-300 hover:bg-SILVER hover:bg-opacity-25 focus:z-10 focus:border-BLUE focus:outline-none focus:ring-1 focus:ring-BLUE"
              >
                {isFavorite(poi._id) ? (
                  <FavoriteIcon />
                ) : (
                  <FavoriteBorderIcon />
                )}
              </button>
              <button
                onClick={handleOpenModal}
                type="button"
                className="relative -ml-px inline-flex items-center border border-BLUE bg-ALICEBLUE px-4 py-2 text-sm font-semibold text-BLUE duration-300 hover:bg-SILVER hover:bg-opacity-25 focus:z-10 focus:border-BLUE focus:outline-none focus:ring-1 focus:ring-BLUE"
              >
                <BookmarkAddIcon />
              </button>
              <Modal
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <ModalAddToWishlist
                  poiId={poi._id}
                  closeParentModal={handleCloseModal}
                />
              </Modal>
              <button
                type="button"
                onClick={() => seeOnMap(poi)}
                className="relative -ml-px inline-flex items-center rounded-r-md border border-BLUE bg-ALICEBLUE px-4 py-2 text-sm font-semibold text-BLUE duration-300 hover:bg-SILVER hover:bg-opacity-25 focus:z-10 focus:border-BLUE focus:outline-none focus:ring-1 focus:ring-BLUE"
              >
                <MapIcon />
              </button>
            </span>
            {poi.sensors.length > 0 && (
              <span className="w-fit">
                <Sensors id={poi._id} />
              </span>
            )}
          </div>
          <div className="mt-2 border-t border-gray-200 px-4">
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                <dt className="flex flex-row items-center text-sm font-semibold text-RICHBLACK">
                  <DescriptionIcon sx={{ mr: 1 }} />{" "}
                  {trad[lang].poiDetails.description}:
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {lang === "fr"
                    ? poi?.long_description?.fr ||
                      poi?.short_description?.fr ||
                      "Ce point d'intérêt n'a pas de description."
                    : poi?.long_description?.en ||
                      poi?.short_description?.en ||
                      "This point of interest has no description."}
                </dd>
              </div>
              {poi.address ? (
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                  <dt className="flex flex-row items-center text-sm font-semibold text-RICHBLACK">
                    <HomeIcon sx={{ mr: 1 }} /> {trad[lang].poiDetails.address}:
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {poi.address}, {poi.postal_code} {poi.city}
                  </dd>
                </div>
              ) : null}
              {poi.tel ? (
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                  <dt className="flex flex-row items-center text-sm font-semibold text-RICHBLACK">
                    <PhoneIcon sx={{ mr: 1 }} /> {trad[lang].poiDetails.phone}:
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {poi.tel}
                  </dd>
                </div>
              ) : null}
              {poi.email ? (
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                  <dt className="flex flex-row items-center text-sm font-semibold text-RICHBLACK">
                    <EmailIcon sx={{ mr: 1 }} /> {trad[lang].poiDetails.email}:
                  </dt>
                  <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">
                    <a
                      className="w-fit text-BLUE hover:text-PRIMARY"
                      href={`mailto:${poi.email}`}
                    >
                      {poi.email}
                    </a>
                  </dd>
                </div>
              ) : null}
              {poi.facebook ? (
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                  <dt className="flex flex-row items-center text-sm font-semibold text-RICHBLACK">
                    <FacebookIcon sx={{ mr: 1 }} />{" "}
                    {trad[lang].poiDetails.facebook}:
                  </dt>
                  <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">
                    <a
                      className="w-fit text-BLUE hover:text-PRIMARY"
                      href={poi.facebook}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {`www.facebook.com/${poi.name}`}
                    </a>
                  </dd>
                </div>
              ) : null}

              {poi.website ? (
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                  <dt className="flex flex-row items-center text-sm font-semibold text-RICHBLACK">
                    <LanguageIcon sx={{ mr: 1 }} />{" "}
                    {trad[lang].poiDetails.website}:
                  </dt>
                  <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">
                    <a
                      className="w-fit text-BLUE hover:text-PRIMARY"
                      href={poi.website}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {poi.website}
                    </a>
                  </dd>
                </div>
              ) : null}

              {Object.values(poi.hours).flat(2).length > 0 ? (
                <div className="min-h-[162px] py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                  {poi.hours ? (
                    <dt className="flex max-h-8 flex-row items-center text-sm font-semibold text-RICHBLACK">
                      <button
                        onClick={handleDropDown}
                        className="flex flex-row items-center text-sm font-semibold text-RICHBLACK duration-300 hover:text-PRIMARY"
                      >
                        <WatchLaterIcon sx={{ mr: 1 }} />{" "}
                        {trad[lang].poiDetails.hours}
                        {dropDown === false ? (
                          <ArrowDropDownIcon />
                        ) : (
                          <ArrowDropUpIcon />
                        )}
                      </button>
                    </dt>
                  ) : null}

                  {dropDown === true ? (
                    <dd className="mt-3 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {Object.entries(poi.hours).map((day: any) => {
                        return (
                          day[1].length > 0 && (
                            <div
                              key={randomId}
                              className="flex max-w-[200px] flex-row justify-between gap-3 text-sm text-gray-900"
                            >
                              <p>{trad[lang].days[day[0]]}: </p>
                              <div>
                                {day[1].map((hour) => {
                                  return (
                                    <div key={randomId}>
                                      <p>
                                        {hour[0]} - {hour[1]}
                                      </p>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )
                        );
                      })}
                    </dd>
                  ) : (
                    <button
                      onClick={handleDropDown}
                      className="mt-3 text-sm text-gray-900"
                    >
                      {trad[lang].days.display}
                    </button>
                  )}
                </div>
              ) : null}
            </dl>
          </div>
        </div>
      </div>
      <div className="w-full lg:w-2/3">
        <Divider />
        <span className="mb-6 flex flex-row items-center px-4 text-sm font-semibold text-RICHBLACK">
          <LocationOnIcon sx={{ mr: 1 }} />{" "}
          <p>{trad[lang].poiDetails.nearby}</p>
        </span>

        <Nearby latitude={poi.latitude} longitude={poi.longitude} />
      </div>
      <div className="w-full">
        <Divider />
        <Jetcode />
      </div>
    </div>
  );
};

export default PoiDetails;
