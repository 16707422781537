import HotelIcon from "@mui/icons-material/Hotel";
import GavelIcon from "@mui/icons-material/Gavel";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import FilterHdrIcon from "@mui/icons-material/FilterHdr";
import LightModeIcon from "@mui/icons-material/LightMode";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import CelebrationIcon from "@mui/icons-material/Celebration";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import BorderOuterIcon from "@mui/icons-material/BorderOuter";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import TheaterComedyIcon from "@mui/icons-material/TheaterComedy";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import DownhillSkiingIcon from "@mui/icons-material/DownhillSkiing";
import SportsBaseballIcon from "@mui/icons-material/SportsBaseball";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import ScienceIcon from "@mui/icons-material/Science";
import SoupKitchenIcon from "@mui/icons-material/SoupKitchen";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import { THEMES } from "../../constants";

export const CatIcon = (cat) => {
  switch (cat) {
    case "HOTELLERIE":
    case "HEBERGEMENT_COLLECTIF":
    case "HEBERGEMENT_LOCATIF":
    case "HOTELLERIE_PLEIN_AIR":
      return <HotelIcon sx={{ color: THEMES.BLUE }} />;
    case "RESTAURATION":
      return <RestaurantIcon sx={{ color: THEMES.BLUE }} />;
    case "DEGUSTATION":
      return <AgricultureIcon sx={{ color: THEMES.BLUE }} />;
    case "ACTIVITE":
      return <LocalActivityIcon sx={{ color: THEMES.BLUE }} />;
    case "FETE_ET_MANIFESTATION":
      return <CelebrationIcon sx={{ color: THEMES.BLUE }} />;
    case "COMMERCE_ET_SERVICE":
      return <ShoppingBasketIcon sx={{ color: THEMES.BLUE }} />;
    case "DOMAINE_SKIABLE":
      return <DownhillSkiingIcon sx={{ color: THEMES.BLUE }} />;
    case "EQUIPEMENT":
      return <LocationCityIcon sx={{ color: THEMES.BLUE }} />;
    case "PATRIMOINE_CULTUREL":
      return <TheaterComedyIcon sx={{ color: THEMES.BLUE }} />;
    case "PATRIMOINE_NATUREL":
      return <FilterHdrIcon sx={{ color: THEMES.BLUE }} />;
    case "SEJOUR_PACKAGE":
      return <LightModeIcon sx={{ color: THEMES.BLUE }} />;
    case "STRUCTURE":
      return <GavelIcon sx={{ color: THEMES.BLUE }} />;
    case "TERRITOIRE":
      return <BorderOuterIcon sx={{ color: THEMES.BLUE }} />;
    case "ASSOCIATIONS_MUSICALES":
      return <LibraryMusicIcon sx={{ color: THEMES.BLUE }} />;
    case "ASSOCIATIONS_SPORTIVES":
      return <SportsBaseballIcon sx={{ color: THEMES.BLUE }} />;
    case "ASSOCIATIONS_LOISIRS":
      return <SportsTennisIcon sx={{ color: THEMES.BLUE }} />;
    case "ASSOCIATIONS_CULTURELLES":
      return <MenuBookIcon sx={{ color: THEMES.BLUE }} />;
    case "ALL":
      return <AllInclusiveIcon sx={{ color: THEMES.BLUE }} />;
    case "SENTEURS":
      return <ScienceIcon sx={{ color: THEMES.BLUE }} />;
    case "SERVICES":
      return <RoomServiceIcon sx={{ color: THEMES.BLUE }} />;
    case "SAVEURS":
      return <SoupKitchenIcon sx={{ color: THEMES.BLUE }} />;
    case "METIERS_ART":
      return <ColorLensIcon sx={{ color: THEMES.BLUE }} />;
    default:
      return "shapes";
  }
};
