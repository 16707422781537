import "../../index.css";
import axios from "axios";
import trad from "../../lang/traduction";
import { CatIcon } from "../utils/CatIcon";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import { API_URL, HEADERS, THEMES, ID_APPLICATION } from "../../constants";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Box, Button, Modal, Typography } from "@mui/material";

const style = {
  bgcolor: THEMES.ALICEBLUE,
  boxShadow: 24,
  p: 4,
  borderRadius: "6px",
  scrollBar: "none",
};

const FilterCatMap = ({
  categories,
  setCategories,
  selectedCategorieId,
  setSelectedCategorieId,
}) => {
  const { lang } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // GET ALL POI
  const getCategories = async () => {
    try {
      const { data } = await axios.get(
        `${API_URL}/appPoi/category?idApplication=${ID_APPLICATION}`,
        HEADERS
      );

      setCategories(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <Box className="absolute left-12 z-[1500] p-[10px]">
      <Button
        variant="contained"
        sx={{
          backgroundColor: THEMES.BLUE,
          color: THEMES.ALICEBLUE,
          "&:hover": {
            color: THEMES.RICHBLACK,
            backgroundColor: THEMES.PRIMARY,
          },
        }}
        onClick={handleOpen}
      >
        <FilterListIcon />
        {trad[lang].maps.filter}
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box className="flex justify-center">
          <Box
            sx={style}
            className="custom absolute top-[20%] h-[75%] w-[80%] overflow-scroll lg:top-[50%] lg:left-[50%] lg:h-auto lg:w-[45%] lg:translate-x-[-50%] lg:translate-y-[-50%]"
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {trad[lang].maps.filterPlaceholder}
            </Typography>
            <Box className="gap-1 lg:grid lg:w-auto lg:grid-cols-3 lg:grid-rows-1">
              {categories?.result?.map((cat) => (
                <Box
                  key={cat._id}
                  className="mt-2 flex cursor-pointer flex-col justify-between rounded-2xl border-[1px] p-3 text-RICHBLACK hover:bg-SILVER hover:bg-opacity-25 lg:h-32"
                  sx={{
                    border:
                      selectedCategorieId === cat.category_id
                        ? `3px solid ${THEMES.BLUE}`
                        : `1px solid ${THEMES.BLUE}`,
                    backgroundColor:
                      selectedCategorieId === cat.category_id
                        ? "#e0e6ed"
                        : THEMES.ALICEBLUE,
                  }}
                  onClick={() => {
                    setSelectedCategorieId(cat.category_id);
                    setOpen(false);
                  }}
                >
                  {CatIcon(cat.type)}
                  <p className="text-base font-medium text-BLUE">
                    {lang === "fr" ? cat.name.fr : cat.name.en}
                  </p>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default FilterCatMap;
