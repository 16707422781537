import trad from "../lang/traduction";
import { THEMES } from "../constants";
import Thermo from "@mui/icons-material/Thermostat";
import People from "@mui/icons-material/EmojiPeople";

export const iconSensor = (val) => {
  switch (val.sensorType) {
    case "eurecam":
      return <People />;
    case "arduino":
      return <Thermo />;
  }
};

export const valueSensor = (val, lang, isCarousel = false) => {
  switch (val.sensorType) {
    case "eurecam":
      const nbPerson = val.samples.occupancy_state.split(",")[1];
      const persons =
        nbPerson > 1
          ? `${trad[lang].sensorInfo.person}s`
          : trad[lang].sensorInfo.person;
      if (isCarousel) return <p>{`${nbPerson} pers.`}</p>;
      return <p>{`${nbPerson} ${persons}`}</p>;
    case "arduino":
      return <p>{parseFloat(val.samples.temp3).toFixed(1) + " °C"}</p>;
    default:
      return null;
  }
};

export const colorSensor = (val) => {
  switch (val.sensorType) {
    case "eurecam":
      return THEMES.PRIMARY;
    case "arduino":
      const value = val.samples.temp3;
      if (value <= 0) return THEMES.PRIMARY;
      if (value > 30) return "#c91c28";
      if (value > 20) return "#e36714";
      if (value > 0) return "#e0a510";
      else return "black";
    default:
      return "black";
  }
};
