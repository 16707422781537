import React, { useEffect, useState } from "react";
import { PublicRoutes } from "./routes/PublicRoutes";

function App() {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      const jetcodeScript = document.createElement("script");
      jetcodeScript.src =
        "https://jetcode.dag-system.com/jetcodes/fr?customerID=1e212747689a2a5949039c241a30f44a07aefa67c9ea9f53cdff49751a064315";
      jetcodeScript.defer = true;
      jetcodeScript.addEventListener("load", () => console.log("loaded"));
      document.body.appendChild(jetcodeScript);
      setInitialized(true);
    }
  }, []);
  return (
    <div className="App">
      <PublicRoutes />

      {/* <div
        style={{ zIndex: 1000 }}
        className="basket-jetcode fixed top-[10px] right-[40px] lg:right-[6px] lg:top-[10px]"
        css-jetcode-href="https://content.dag-system.com/webapp_tourisme/Basket.css"
        data-basket-id="0"
      /> */}
    </div>
  );
}

export default App;
