import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
  paper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  modalContent: {
    position: "absolute",
    width: 300,
    border: "2px solid #bbbaba",
    borderRadius: "10px",
    padding: 10,
    backgroundColor: "#F2F7FC",
    alignSelf: "center",
    justifySelf: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  avatar: {
    margin: 10,
    marginLeft: "auto",
    marginRight: "auto",
  },
  logo: {
    margin: 10,
    textAlign: "center",
  },
  form: {
    width: "100%",
    marginTop: 10,
  },
  submit: {
    margin: 10,
  },
});

export default useStyles;
