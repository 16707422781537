const trad = {
  /* --↓↓↓--FRENCH VERSION--↓↓↓-- */
  fr: {
    poiDetails: {
      description: "Description",
      address: "Adresse",
      phone: "Téléphone",
      email: "Email",
      website: "Site web",
      facebook: "Facebook",
      noInformation: "Aucune information disponible",
      noDescription: "Aucune description disponible pour ce point d'intérêt.",
      nearby: "Points d'intérêt à proximité",
      hours: "Horaires",
    },
    days: {
      monday: "Lundi",
      tuesday: "Mardi",
      wednesday: "Mercredi",
      thursday: "Jeudi",
      friday: "Vendredi",
      saturday: "Samedi",
      sunday: "Dimanche",
      display: "Cliquer pour afficher les horaires",
    },
    error: {
      error: "ERREUR 404",
      title: "Oh ! Il semble que vous soyez perdu.",
      subtitle: "La page que vous recherchez n'existe pas.",
      home: "Retour à l'accueil",
    },
    navbar: {
      home: "Accueil",
      map: "Carte",
      challenges: "Challenges",
      login: "Connexion",
      logout: "Déconnexion",
      account: "Mon compte",
      search: "Rechercher...",
      noResults: "Aucun résultat",
    },
    challenges: {
      title: "Challenges",
      detailsTitle: "Détails du challenge",
      start: "Début",
      end: "Fin",
      noDate: "Aucune date indiquée",
      sports: "Sports",
      select: "Sélectionner un sport",
      ranking: "Classement",
      noRanking: "Aucun classement disponible",
      rank: "Rang",
      name: "Nom",
      time: "Temps",
      date: "Date",
    },
    maps: {
      favorites: "Favoris",
      whishlist: "Liste de souhaits",
      localisation: "Localisation",
      noWebsite: "Aucun site-web de disponible",
      noDescription: "Aucune description de disponible",
      noEmail: "Aucun email de disponible",
      noPhone: "Aucun numéro de téléphone de disponible",
      noAdress: "Aucune adresse de disponible",
      description: "Description",
      coordinates: "Contact",
      noCoordinates: "Aucunes informations de contact disponibles",
      filter: "Filtrer",
      filterPlaceholder: "Filtrer par catégorie",
      moreAbout: "En savoir plus",
      displayCourse: "Afficher le parcours",
    },
    connection: {
      title: "Connexion",
      validEmail: "Doit être une adresse email valide",
      requiredEmail: "L'adresse email est requise",
      labelId: "Nom d'utilisateur",
      validPassword: "Votre mot de passe doit faire 8 caractères au minimum",
      requiredPassword: "Le mot de passe est requis",
      labelPassword: "Mot de passe",
      errorConnection: "Quelque chose s'est mal passé",
      rememberMe: "Se souvenir de moi",
      forgotPassword: "Mot de passe oublié ?",
      connectMe: "Me connecter",
      invalid: "Identifiants non reconnus",
      disconnected: "Vous êtes déconnecté",
      message: "Connectez-vous à votre compte",
      noAccount: "Pas encore de compte ?",
      login: "Identifiant",
    },
    resetPassword: {
      title: "Mot de passe oublié",
      enterMail: "Entrez votre email afin de récupérer votre mot de passe",
      validEmail: "Doit être une adresse mail valide",
      requiredEmail: "L'adresse email est requise",
      errorConnection: "Quelque chose s'est mal passé",
      labelEmail: "Adresse email",
      labelPseudo: "Nom d'utilisateur",
      labelReset: "Réinitialisation du mot de passe",
      returnSignin: "Retour à la connexion",
      invalid: "Email non reconnu",
      emailSuccess: "Email envoyé avec succès",
      success: "Votre mot de passe à été correctement modifié",
      forgotPseudo: "Nom d'utilisateur oublié ?",
      selectPseudo: "Veuillez séléctionner votre nom d'utilisateur",
      noUsers: "Aucun utilisateur pour cet email",
      retry: "Veuillez réessayer avec un email différent.",
      emailLink: "Recevoir le lien de réinitialisation par email",
      labelPassword: "Mot de passe",
      labelPasswordConfirmation: "Confirmation du mot de passe",
      description:
        "Saisissez simplement votre adresse e-mail ci-dessous et nous vous enverrons un lien, vous disposerez de 30 minutes pour créer un nouveau mot de passe !",
      invalidLimit: "Délai de réinitialisation dépassé, veuillez réessayer",
      alreadyHave: "Vous avez déjà un compte ? Connectez vous !",
      createAccount: "Créez un compte",
      resetPassword: "Réinitialiser le mot de passe",
      placeholderEmail: "Entrez votre email",
      placeholderPseudo: "Entrez votre nom d'utilisateur",
    },
    usercreation: {
      title: "Créez votre compte",
      labelLastName: "Nom",
      labelFirstname: "Prénom",
      lastName: "Entrez votre nom",
      firstname: "Entrez votre prénom",
      labelEmail: "Adresse email",
      labelUserName: "Nom d'utilisateur",
      userName: "Entrez un nom d'utilisateur",
      email: "Entrez votre email",
      labelPassword: "Mot de passe",
      labelPasswordConfirmation: "Confirmation du mot de passe",
      password: "Entrez votre mot de passe",
      passwordConfirmation: "Confirmez votre mot de passe",
      required: "Ce champ est requis",
      minValidation: "Ce champ doit contenir 2 caractères au minimum",
      maxValidation: "Ce champ doit contenir 20 caractères au maximum",
      passwordMinValidation: "Ce champ doit contenir 5 caractères au minimum",
      passwordNotMatches: "Les mots de passe ne correspondent pas",
      emailValidate: "Ceci n'est pas un email valide",
      error: "Une erreur est survenue",
      labelBirthdate: "Date de naissance",
      buttonValidate: "Valider",
    },
    utils: {
      success: "Opération effectuée avec succès",
      cancel: "Annuler les modifications",
      add: "Ajouter",
    },
    search: {
      searchResult: "Résultats de votre recherche",
      noResults: "Aucun résultat pour le moment",
      searchPlaceholder: "Rechercher",
    },
    userProfile: {
      mainTitle: "Mon compte",
      title: "Profil Utilisateur",
      firstname: "Prénom",
      lastname: "Nom",
      birthdate: "Date de naissance",
      login: "Identifiant",
      mail: "Email",
      favorites: "Favoris",
      whishlist: "Liste de souhaits",
      noFavorites: "Aucun favoris pour le moment.",
      noWishlist: "Aucune liste de souhaits pour le moment.",
      emptyWishlist: "Votre liste de souhaits est vide",
    },
    catFilter: {
      title: "Choisir une catégorie",
    },
    loading: {
      title: "Chargement...",
    },
    sensorInfo: {
      person: "personne",
    },
    favorites: {
      add: "Vous avez bien ajouté ce point d'intérêt en favori",
      remove: "Vous avez bien supprimé ce point d'intérêt de vos favoris",
    },
    categories: {
      noResults: "Pas de résultats",
      yes: "Oui",
      no: "Non",
      delete: "Supprimer",
      nameWishlist: "Nom de la liste de souhaits",
    },
    wishlist: {
      add: "Vous avez bien ajouté ce point d'intérêt à votre liste de souhaits",
      remove:
        "Vous avez bien supprimé ce point d'intérêt de votre liste de souhaits",
      addWishlist: "Ajouter une nouvelle liste de souhaits",
      createWishlist: "Créer une liste de souhaits",
      success: "Votre liste de souhaits a bien été créée",
      modalAddToWishlist:
        "A quelle liste de souhait voudriez vous ajouter ce point d'intérêt ?",
      noPoiInWishlist:
        "Vous n'avez encore rien ajouté à cette liste de souhait",
      deleteWishlist:
        "Souhaitez-vous réellement supprimer cette liste de souhaits ?",
      noWishlist: "Vous n'avez pas de liste de souhaits",
      wishlistDelete: "Votre liste de souhaits a bien été supprimée",
      addPoiWishlist: "Votre point d'intérêt a bien été ajouté à la liste",
      yes: "Oui",
      no: "Non",
      validate: "Valider",
      delete: "Supprimer",
      nameWishlist: "Nom de la liste de souhaits",
    },
    catSelector: {
      more: "Plus",
    },
    survey: {
      postalCode: "Code postal",
      organization:
        "Dénomination sociale (si Entreprise individuelle Nom du dirigeant)",
      city: "Ville",
    },
  },
  /* --↓↓↓--ENGLISH VERSION--↓↓↓-- */
  en: {
    poiDetails: {
      description: "Description",
      address: "Address",
      phone: "Phone number",
      email: "Email",
      website: "Website",
      facebook: "Facebook",
      noInformation: "No information available",
      noDescription: "No description available for this point of interest",
      nearby: "Nearby",
      hours: "Opening hours",
    },
    days: {
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
      sunday: "Sunday",
      display: "Click to display the schedules",
    },
    error: {
      error: "404 ERROR",
      title: "Uh, it seems you are lost.",
      subtitle: "The page you are looking for does not exist.",
      home: "Back to homepage",
    },
    navbar: {
      home: "Home",
      map: "Map",
      challenges: "Challenges",
      login: "Login",
      logout: "Logout",
      account: "My account",
      search: "Search...",
      noResults: "No results",
    },
    challenges: {
      title: "Challenges",
      detailsTitle: "Challenge Details",
      start: "Start",
      end: "End",
      noDate: "No date indicated",
      sports: "Sports",
      select: "Select a sport",
      ranking: "Ranking",
      noRanking: "No ranking available",
      rank: "Rank",
      name: "Name",
      time: "Time",
      date: "Date",
    },
    maps: {
      favorites: "Favorites",
      whishlist: "Wishlist",
      localisation: "Location",
      noWebsite: "No website available",
      noDescription: "No description available",
      noEmail: "No email available",
      noPhone: "No phone number available",
      noAdress: "No address available",
      description: "Description",
      coordinates: "Contact",
      noCoordinates: "No contact informations available",
      filter: "Filter",
      filterPlaceholder: "Filter by category",
      moreAbout: "More information",
      displayCourse: "Display course",
    },
    connection: {
      title: "Login",
      validEmail: "Must be a valid email address",
      requiredEmail: "Email address is required",
      labelId: "User name",
      validPassword: "Your password must be at least 8 characters long",
      requiredPassword: "Password is required",
      labelPassword: "Password",
      errorConnection: "Something went wrong",
      rememberMe: "Remember me",
      forgotPassword: "Forgot your password?",
      connectMe: "Log me in",
      invalid: "Unrecognized identifiers",
      disconnected: "You are disconnected",
      message: "Log in to your account",
      noAccount: "No account yet?",
      login: "Identifier",
    },
    resetPassword: {
      title: "Forgot your password?",
      enterMail: "Enter your email to retrieve your password",
      validEmail: "Must be a valid email address",
      requiredEmail: "Email address is required",
      errorConnection: "Something went wrong",
      labelEmail: "Email address",
      labelPseudo: "User name",
      labelReset: "Reset password",
      returnSignin: "Back to the connection",
      invalid: "Email not recognized",
      emailSuccess: "Email sent successfully",
      success: "Your password has been correctly changed",
      forgotPseudo: "Forgot your username?",
      selectPseudo: "Please select your username",
      noUsers: "No users for this email",
      retry: "Please try again with a different email.",
      emailLink: "Receive the reset link by email",
      labelPassword: "Password",
      labelPasswordConfirmation: "Password confirmation",
      description:
        "Simply enter your email address below and we'll send you a link and you'll have 30 minutes to create a new password!",
      invalidLimit: "Reset time out, please try again",
      alreadyHave: "Already have an account? Sign in !",
      createAccount: "Create an account",
      resetPassword: "Reset password",
      placeholderEmail: "Enter your email",
      placeholderPseudo: "Enter your username",
    },
    usercreation: {
      title: "Create your account",
      labelLastName: "Last name",
      labelFirstname: "First name",
      lastName: "Enter your name",
      firstname: "Enter your first name",
      labelEmail: "Email address",
      labelUserName: "User name",
      userName: "Enter a user name",
      email: "Enter your email",
      labelPassword: "Password",
      labelPasswordConfirmation: "Password confirmation",
      password: "Enter your password",
      passwordConfirmation: "Confirm your password",
      required: "This field is required",
      minValidation: "This field must contain at least 2 characters",
      maxValidation: "This field must contain a maximum of 20 characters",
      passwordMinValidation: "This field must contain at least 5 characters",
      passwordNotMatches: "Passwords do not match",
      emailValidate: "This is not a valid email",
      error: "An error has occurred",
      labelBirthdate: "Birthdate",
      buttonValidate: "Confirm",
    },
    utils: {
      success: "Operation successfully completed",
      cancel: "Cancel changes",
    },
    search: {
      searchResult: "Your search results",
      noResults: "No results for the moment",
      searchPlaceholder: "Search",
    },
    userProfile: {
      mainTitle: "My account",
      title: "User profile",
      firstname: "First name",
      lastname: "Last name",
      birthdate: "Birthdate",
      login: "Login",
      mail: "Email",
      favorites: "Favorites",
      whishlist: "Wishlist",
      noFavorites: "No favorites for the moment.",
      noWishlist: "No wishlist for the moment.",
    },
    catFilter: {
      title: "Select a category",
    },
    loading: {
      title: "Loading...",
    },
    sensorInfo: {
      person: "person",
    },
    favorites: {
      add: "You have added this point of interest as a favorite",
      remove: "You did remove this point of interest from your favorites",
    },

    categories: {
      noResults: "No results",
    },
    wishlist: {
      add: "You have added this point of interest to your wishlist",
      remove: "You did remove this point of interest from your wishlist",
      addWishlist: "Create a wishlist",
      createWishlist: "Create a wishlist",
      success: "Wishlist successfully created",
      modalAddToWishlist:
        "To which wishlist would you add this point of interest?",
      noPoiInWishlist: "You have not added anything to this wishlist yet",
      deleteWishlist: "Are you sure you want to delete this wishlist?",
      noWishlist: "You have not created any wishlist yet",
      validate: "Validate",
      yes: "Yes",
      no: "No",
      delete: "Delete",
      nameWishlist: "Wishlist name",
      wishlistDelete: "Your wishlist has been deleted",
      addPoiWishlist: "Your point of interest has been added to this wishlist",
    },
    catSelector: {
      more: "More",
    },
    survey: {
      postalCode: "Postal Code",
      organization: "Organization",
      city: "City",
    },
  },
};

export default trad;
