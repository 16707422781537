import React from "react";
import { useParams } from "react-router-dom";

function Jetcode() {
  const { id } = useParams();

  return (
    <div className="w-full">
      {id === "62a20aae86ff9e1c04dadfbf" && (
        <div
          className="eventsubscription-jetcode my-[50px]"
          data-eventsubscription-id="33398_1654788464709"
          css-jetcode-href="https://content.dag-system.com/externalcontent/eventsubscription/eventsubscription_blue.css"
        ></div>
      )}
      {/* <div
        className="product-jetcode my-[50px]"
        data-product-id="1621"
        jetcode-options="maxNbDateDisplay=5"
        css-jetcode-href={`https://content.dag-system.com/webapp_tourisme/Jetcode.css`}
      ></div> */}
    </div>
  );
}

export default Jetcode;
