import { Link } from "react-router-dom";
import { THEMES } from "../../constants";
import trad from "../../lang/traduction";
import { useContext, useState } from "react";
import RoomIcon from "@mui/icons-material/Room";
import EmailIcon from "@mui/icons-material/Email";
import IconButton from "@mui/material/IconButton";
import { Box, Button, Modal } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LanguageIcon from "@mui/icons-material/Language";
import { UserContext } from "../../contexts/UserContext";
import { MapContext } from "../../contexts/MapContext";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import ModalAddToWishlist from "../Wishlist/ModalAddToWishlist";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

const CardInfoPoi = ({
  name,
  categories,
  image,
  description,
  email,
  address,
  socialmedia,
  phone,
  id,
  setOpen,
  centerTo,
  displayCourse,
  setDisplayCourse,
}) => {
  const handleClose = () => {
    setOpen(false);
    setSelectedPoi(undefined);
    setDisplayCourse(false);
  };

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => setOpenModal(false);

  const { toggleFavorite, isFavorite, lang, user } = useContext(UserContext);
  const { setSelectedPoi } = useContext(MapContext);

  // FAVORITES
  const handleChange = (e) => {
    e.stopPropagation();
    toggleFavorite(id);
  };

  return (
    <div
      id={id}
      className="scroll-map absolute z-[1500] flex h-[220px] w-full flex-row bg-ALICEBLUE shadow-2xl lg:top-20 lg:right-0 lg:mx-5 lg:h-[90%] lg:w-[400px] lg:flex-col lg:rounded-md"
    >
      <CancelIcon
        className="absolute right-1 top-1 cursor-pointer rounded-full bg-BLUE"
        sx={{ fontSize: 24, color: "#F2F7FC" }}
        onClick={handleClose}
      />
      <img
        src={image}
        alt={name}
        className="h-full w-1/3 object-cover object-center lg:h-64 lg:w-full lg:rounded-t-md"
        style={{
          imageRendering: "pixelated",
        }}
      />
      <div className="flex h-full w-full flex-col">
        <Box className="gap-1 lg:p-3">
          <h2 className="mt-1 ml-1 w-[calc(100%-36px)] overflow-hidden text-ellipsis whitespace-nowrap text-lg font-bold lg:w-full lg:text-xl">
            {name}
          </h2>
          {categories?.length > 0 && (
            <h3 className="mt-[2px] ml-1 text-sm">
              {lang === "fr"
                ? categories[0]?.name?.fr
                : categories[0]?.name?.en}
            </h3>
          )}
          <Box className="my-2 flex w-full items-center justify-center gap-3 border-y-2 border-SILVER p-4 lg:gap-10">
            <Box className="flex flex-col items-center justify-center">
              <Box className="mb-1 flex h-[40px] w-[40px] flex-row items-center justify-center rounded-full border-2 border-BLUE">
                <IconButton
                  size="small"
                  aria-label="add to favorites"
                  sx={{ color: THEMES.WARNING, fontSize: 30 }}
                  onClick={(e) => handleChange(e)}
                >
                  {isFavorite(id) ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                </IconButton>
              </Box>
              <p className="text-xs">{trad[lang].maps.favorites}</p>
            </Box>
            <Box className="flex flex-col items-center justify-center">
              {user.Nom ? (
                <button
                  onClick={handleOpenModal}
                  className="mb-1 flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full border-2 border-BLUE"
                >
                  <BookmarkAddIcon sx={{ fontSize: 30, color: THEMES.BLUE }} />
                </button>
              ) : (
                <Link to={`${process.env.PUBLIC_URL}/sign-in`}>
                  <button className="mb-1 flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full border-2 border-BLUE">
                    <BookmarkAddIcon
                      sx={{ fontSize: 30, color: THEMES.BLUE }}
                    />
                  </button>
                </Link>
              )}

              <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ zIndex: 9000 }}
              >
                <ModalAddToWishlist
                  poiId={id}
                  closeParentModal={handleCloseModal}
                />
              </Modal>
              <p className="text-center text-xs">{trad[lang].maps.whishlist}</p>
            </Box>
            <Box className="flex flex-col items-center justify-center">
              <Box
                className="mb-1 flex h-[40px] w-[40px] items-center justify-center rounded-full border-2 border-BLUE"
                onClick={centerTo}
              >
                <IconButton>
                  <GpsFixedIcon sx={{ fontSize: 30, color: THEMES.BLUE }} />
                </IconButton>
              </Box>
              <p className="text-xs">{trad[lang].maps.localisation}</p>
            </Box>
            {displayCourse}
          </Box>
          <Box className="hidden w-[95%] flex-col lg:flex">
            <h3 className="text-base font-bold text-RICHBLACK">
              {trad[lang].maps.description}
            </h3>
            <p className="mt-2 text-sm text-RICHBLACK">
              {lang === "fr" ? description.fr : description.en}
            </p>
          </Box>
        </Box>
        <Box className="mx-3 hidden flex-col lg:flex">
          <h3 className="text-base font-bold text-RICHBLACK">
            {trad[lang].maps.coordinates}
          </h3>
          {address ? (
            <Box className="mb-4 mt-2 flex flex-row items-center">
              <RoomIcon sx={{ fontSize: 20, color: THEMES.BLUE }} />
              <p className="ml-2 text-sm text-RICHBLACK">{address}</p>
            </Box>
          ) : null}
          {email ? (
            <Box className="mb-4 mt-2 flex flex-row items-center">
              <EmailIcon sx={{ fontSize: 20, color: THEMES.BLUE }} />
              <a href={`mailto:${email}`}>
                <p className="ml-2 text-sm text-RICHBLACK hover:text-PRIMARY">
                  {email}
                </p>
              </a>
            </Box>
          ) : null}
          {phone ? (
            <Box className="mb-4 mt-2 flex flex-row items-center">
              <LocalPhoneIcon sx={{ fontSize: 20, color: THEMES.BLUE }} />
              <p className="ml-2 text-sm text-RICHBLACK">{phone}</p>
            </Box>
          ) : null}
          {socialmedia ? (
            <Box className="mb-4 mt-2 flex flex-row items-center">
              <LanguageIcon sx={{ fontSize: 20, color: THEMES.BLUE }} />
              <a href={socialmedia}>
                <p className="ml-2 text-sm text-RICHBLACK hover:text-PRIMARY">
                  {socialmedia}
                </p>
              </a>
            </Box>
          ) : null}
        </Box>
        {address === null &&
        email === null &&
        phone === null &&
        socialmedia === null ? (
          <p className="my-4 text-center text-sm italic text-RICHBLACK">
            {trad[lang].maps.noCoordinates}
          </p>
        ) : null}
        <Box className="my-2 flex justify-center lg:my-8">
          <Link to={`${process.env.PUBLIC_URL}/poidetails/${id}`}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: THEMES.BLUE,
                color: THEMES.ALICEBLUE,
                "&:hover": {
                  color: THEMES.RICHBLACK,
                  backgroundColor: THEMES.PRIMARY,
                },
              }}
            >
              {trad[lang].maps.moreAbout}
            </Button>
          </Link>
        </Box>
      </div>
    </div>
  );
};

export default CardInfoPoi;
