import {
  Autocomplete,
  Box,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { SurveyContext } from "../../contexts/SurveyContext";
import { UserContext } from "../../contexts/UserContext";
import trad from "../../lang/traduction";
import axios from "axios";
import { API_URL, HEADERS, ID_APPLICATION } from "../../constants";

import { useDebounce } from "usehooks-ts";

const SurveyIdentity = () => {
  const { identity, setIdentity, setIsIdentityValid } = useContext(
    SurveyContext
  );
  const { lang } = useContext(UserContext);
  const [citisList, setCitiesList] = useState([]);
  const [inputCP, setInputCP] = useState("");
  const debouncedValue = useDebounce<string>(inputCP, 1000);

  const handleChange = (key, value) => {
    if (!value) {
      setCitiesList([]);
    }
    if (key === "postalCode") {
      setIdentity({
        ...identity,
        postalCode: value?.properties?.code || "",
        city: value?.properties?.nom || "",
      });
    } else {
      setIdentity({
        ...identity,
        [key]: value,
      });
    }
  };

  const getCommunes = async (cp) => {
    try {
      const { data } = await axios.get(
        `${API_URL}/appPoi/city/getCommunes?cityCode=${cp}`,
        HEADERS
      );
      setCitiesList(data);
    } catch (err) {
      console.error(err);
    }
  };

  const checkRequired = () => {
    const valid = Object.values(identity).every((y) => y);
    setIsIdentityValid(valid);
  };

  useEffect(() => {
    if (inputCP) {
      getCommunes(inputCP);
    }
  }, [debouncedValue]);

  useEffect(() => {
    checkRequired();
  }, [identity]);

  return (
    <Box sx={{ mb: 2 }}>
      <Typography className="form-label">Vos informations</Typography>
      <Grid container spacing={1}>
        {identity &&
          Object.keys(identity).map((elt, index) => (
            <Grid item sm={elt === "organization" ? 12 : 6} xs={12} key={index}>
              <FormControl fullWidth>
                {elt === "postalCode" && citisList ? (
                  <Autocomplete
                    disablePortal
                    id="cityList"
                    size="small"
                    options={citisList}
                    fullWidth
                    getOptionLabel={(option) =>
                      option.properties.code + " " + option.properties.nom
                    }
                    onInputChange={(e, value) => setInputCP(value)}
                    onChange={(e, value) => handleChange(elt, value)}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Code Postal *" />
                    )}
                  />
                ) : (
                  <TextField
                    id={`${elt}-input-${index}`}
                    size="small"
                    label={trad[lang].survey[elt]}
                    required
                    fullWidth
                    value={identity[elt] ? identity[elt] : ""}
                    onChange={(e) => handleChange(elt, e.target.value)}
                  />
                )}
              </FormControl>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default SurveyIdentity;
