import "../index.css";
import axios from "axios";
import NoPic from "../assets/no-image.png";
import CardPoi from "../components/CardPoi";
import NoPicFr from "../assets/pas-image.png";
import Slider from "../components/Sliders/Slider";
import Loading from "../components/Loaders/Loading";
import { UserContext } from "../contexts/UserContext";
import CatName from "../components/Categories/CatName";
import NewSlider from "../components/Sliders/NewSlider";
import { useContext, useEffect, useState } from "react";
import CatSelector from "../components/Categories/CatSelector";
import { API_URL, HEADERS, ID_APPLICATION, survey_app } from "../constants";
import QuizIcon from "@mui/icons-material/Quiz";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [suggestedList, setSuggestedList] = useState([]);
  const { lang } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getSuggestedPoi = async () => {
    try {
      const { data } = await axios.get(
        `${API_URL}/appPoi/application/${ID_APPLICATION}/homePage`,
        HEADERS
      );

      if (data) {
        setSuggestedList(data.result);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSuggestedPoi();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="py-16 lg:px-24">
      <div className="flex justify-center">
        <Slider />
      </div>
      <CatSelector />
      <div className="overflow-hidden">
        {suggestedList.map((suggestion, index) => {
          return (
            <div key={index} className="mb-6 flex flex-col items-center">
              <div className="ml-4 flex w-full justify-start md:justify-center">
                <CatName
                  name={
                    lang === "fr"
                      ? suggestion.category.name.fr
                      : suggestion.category.name.en
                  }
                  target={`${process.env.PUBLIC_URL}/categories/${suggestion.category.category_id}`}
                />
              </div>
              <NewSlider
                sliderId={index}
                content={suggestion.poi.map((item) => {
                  return (
                    <div className="item" key={item._id}>
                      <CardPoi
                        image={
                          lang === "fr"
                            ? item?.medias[0]
                              ? item.medias[0].url || item.medias[0].url_medium
                              : NoPicFr
                            : item?.medias[0]
                            ? item.medias[0].url || item.medias[0].url_medium
                            : NoPic
                        }
                        name={item.name}
                        target={`${process.env.PUBLIC_URL}/poidetails/${item._id}`}
                        idPoi={item._id}
                        alt={item.name}
                      />
                    </div>
                  );
                })}
              />
            </div>
          );
        })}
      </div>

      {survey_app.includes(ID_APPLICATION) ? (
        <button
          onClick={() => navigate(`${process.env.PUBLIC_URL}/survey`)}
          className="fixed bottom-[10px] right-[50px] rounded-md bg-[#1976D2] px-[20px] py-[10px] shadow-md shadow-gray-400"
        >
          <div className="flex items-center justify-between gap-2 text-white">
            <QuizIcon />
            <p>Accèder au questionnaire</p>
          </div>
        </button>
      ) : null}
    </div>
  );
};

export default Home;
