import { Box } from "@mui/material";
import trad from "../lang/traduction";
import React, { useContext } from "react";
import NoPic from "../assets/no-image.png";
import CardPoi from "../components/CardPoi";
import NoPicFr from "../assets/pas-image.png";
import { UserContext } from "../contexts/UserContext";
import { SearchContext } from "../contexts/SearchContext";

const Results = () => {
  const { results } = useContext(SearchContext);
  const { lang } = useContext(UserContext);

  return (
    <div className="flex w-full flex-col items-center">
      <h3 className="my-8 mx-4 text-center text-3xl font-bold text-RICHBLACK">
        {trad[lang].search.searchResult} :
      </h3>
      <div className="flex flex-wrap justify-center gap-3 md:w-4/5">
        {results?.count > 0 ? (
          results?.result?.map((item: any) => {
            return (
              <div className="item" key={item._id}>
                <CardPoi
                  image={
                    lang === "fr"
                      ? item?.medias[0]
                        ? item.medias[0].url
                        : NoPicFr
                      : item?.medias[0]
                      ? item.medias[0].url
                      : NoPic
                  }
                  name={item.name}
                  alt={item.name}
                  target={`${process.env.PUBLIC_URL}/poidetails/${item._id}`}
                  idPoi={item._id}
                />
              </div>
            );
          })
        ) : (
          <Box className="flex items-center justify-center p-[2%]">
            {trad[lang].search.noResults}
          </Box>
        )}
      </div>
    </div>
  );
};

export default Results;
