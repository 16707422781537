import CardPoi from "../components/CardPoi";
import NewSlider from "../components/Sliders/NewSlider";
import { UserContext } from "../contexts/UserContext";
import trad from "../lang/traduction";
import NoPic from "../assets/no-image.png";
import NoPicFr from "../assets/pas-image.png";
import { Box, Button, Modal, Typography } from "@mui/material";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import ModalCreateWishlist from "../components/Wishlist/ModalCreateWishlist";
import DeleteIcon from "@mui/icons-material/Delete";
import ModalDeleteWishlist from "../components/Wishlist/ModalDeleteWishlist";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";

function UserProfile() {
  const { user, favorites, userWishlists, lang } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteId, setOpenDeleteId] = useState<any>(null);
  const handleOpenDelete = (id: string) => {
    setOpenDeleteId(id);
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
    setOpenDeleteId(null);
  };

  return (
    <div className="flex w-full flex-col py-16 lg:px-24">
      <h3 className="my-8 text-center text-3xl font-bold text-RICHBLACK md:mt-8 md:mb-24">
        {trad[lang].userProfile.mainTitle}
      </h3>
      <div className="flex h-full flex-col gap-12 lg:flex-row">
        <div
          className="mx-1 mt-7 rounded-md border-2 border-BLUE md:ml-12 lg:w-1/3 lg:border-0"
          id="userCardBlock"
        >
          <div className="rounded-md p-4 shadow-inner shadow-gray-400">
            <div>
              <h3 className="text-xl font-semibold leading-6 text-RICHBLACK">
                {trad[lang].userProfile.title}
              </h3>
            </div>
            <div className="mt-5 border-t border-SILVER">
              <dl className="sm:divide-y sm:divide-SILVER">
                <div className="pt-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5 lg:py-4">
                  <dt className="text-sm font-medium italic text-RICHBLACK underline">
                    {trad[lang].userProfile.firstname}:
                  </dt>
                  <dd className="mt-1 text-sm text-RICHBLACK sm:col-span-2 sm:mt-0">
                    {user.Prenom}
                  </dd>
                </div>
                <div className="pt-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5 lg:py-4">
                  <dt className="text-sm font-medium italic text-RICHBLACK underline">
                    {trad[lang].userProfile.lastname}:
                  </dt>
                  <dd className="mt-1 text-sm text-RICHBLACK sm:col-span-2 sm:mt-0">
                    {user.Nom}
                  </dd>
                </div>

                <div className="pt-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5 lg:py-4">
                  <dt className="text-sm font-medium italic text-RICHBLACK underline">
                    {trad[lang].userProfile.login}:
                  </dt>
                  <dd className="mt-1 text-sm text-RICHBLACK sm:col-span-2 sm:mt-0">
                    {user.UserLogin}
                  </dd>
                </div>
                <div className="pt-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5 lg:py-4">
                  <dt className="text-sm font-medium italic text-RICHBLACK underline">
                    {trad[lang].userProfile.mail}:
                  </dt>
                  <dd className="mt-1 text-sm text-RICHBLACK sm:col-span-2 sm:mt-0">
                    {user.Email}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-8 lg:w-2/3 lg:px-12">
          <div className="flex w-full flex-col lg:items-center" id="favBlock">
            <h4 className="mx-4 mb-4 text-xl font-semibold text-RICHBLACK">
              {trad[lang].userProfile.favorites}
            </h4>
            {favorites.length > 0 ? (
              <NewSlider
                sliderId={undefined}
                content={favorites.map((item) => (
                  <div className="item" key={item._id}>
                    <CardPoi
                      image={
                        lang === "fr"
                          ? item?.medias[0]
                            ? item.medias[0].url
                            : NoPicFr
                          : item?.medias[0]
                          ? item.medias[0].url
                          : NoPic
                      }
                      name={item.name}
                      target={`${process.env.PUBLIC_URL}/poidetails/${item._id}`}
                      idPoi={item._id}
                      alt={item.name}
                    />
                  </div>
                ))}
              />
            ) : (
              <p className="w-full text-center text-RICHBLACK">
                {trad[lang].userProfile.noFavorites}
              </p>
            )}
          </div>

          <div className="flex w-full flex-col items-center" id="wishBlock">
            <h4 className="mx-4 mb-4 flex w-full flex-row px-4 text-xl font-semibold text-RICHBLACK lg:justify-center">
              {trad[lang].userProfile.whishlist}
            </h4>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <ModalCreateWishlist handleClose={handleClose} />
            </Modal>
            {userWishlists?.length > 0 ? (
              <div className="mt-2 mb-2 flex w-4/5 flex-col items-center justify-center border-b-[1px] border-BLUE shadow-gray-400 lg:w-1/2">
                {userWishlists?.map((item: any) => (
                  <div
                    className="mx-1 flex w-full flex-row items-center gap-4 border-t-[1px] border-BLUE p-3 hover:bg-gray-100"
                    key={item._id}
                  >
                    <Link
                      to={`${process.env.PUBLIC_URL}/wishlist/${item._id}`}
                      className="w-full"
                    >
                      <h3 className="text-sm font-semibold text-BLUE">
                        {item.title}
                      </h3>
                    </Link>
                    <Button
                      sx={{ width: 24, height: 24, padding: 2 }}
                      variant="contained"
                      color="error"
                      onClick={() => handleOpenDelete(item._id)}
                    >
                      <DeleteIcon sx={{ width: 24, height: 24 }} />
                    </Button>
                    <Modal
                      open={openDelete}
                      onClose={handleCloseDelete}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <ModalDeleteWishlist
                        id={openDeleteId}
                        handleCloseDelete={handleCloseDelete}
                      />
                    </Modal>
                  </div>
                ))}
              </div>
            ) : (
              <p className="mt-4 w-full text-center text-RICHBLACK">
                {trad[lang].userProfile.noWishlist}
              </p>
            )}
            <button
              type="button"
              className="relative mx-auto my-6 flex w-fit items-center justify-center gap-2 rounded-md border border-BLUE bg-ALICEBLUE px-4 py-2 text-sm font-medium text-BLUE duration-300 hover:bg-SILVER hover:bg-opacity-25 lg:m-4"
              onClick={handleOpen}
            >
              <BookmarkAddIcon />
              <p>{trad[lang].wishlist.createWishlist}</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
