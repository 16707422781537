import { THEMES } from "../../constants";
import HotelIcon from "@mui/icons-material/Hotel";
import GavelIcon from "@mui/icons-material/Gavel";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import FilterHdrIcon from "@mui/icons-material/FilterHdr";
import LightModeIcon from "@mui/icons-material/LightMode";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import CelebrationIcon from "@mui/icons-material/Celebration";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import BorderOuterIcon from "@mui/icons-material/BorderOuter";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import TheaterComedyIcon from "@mui/icons-material/TheaterComedy";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import DownhillSkiingIcon from "@mui/icons-material/DownhillSkiing";
import SportsBaseballIcon from "@mui/icons-material/SportsBaseball";

// <path d="M1 20V5H3V15H11V7H19Q20.65 7 21.825 8.175Q23 9.35 23 11V20H21V17H3V20ZM7 14Q5.75 14 4.875 13.125Q4 12.25 4 11Q4 9.75 4.875 8.875Q5.75 8 7 8Q8.25 8 9.125 8.875Q10 9.75 10 11Q10 12.25 9.125 13.125Q8.25 14 7 14Z" />
// <circle  cx="15" cy="13"  r="15" stroke="black" stroke-width="1" fill="red" />

export const getCat = (cat, size = 45) => {
  const catInfo = {
    HOTELLERIE: {
      color: "#3B44FF",
      icon: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 55" height="${size}" width="${size}" fill="#3B44FF">
          <path d="M1 20V5H3V15H11V7H19Q20.65 7 21.825 8.175Q23 9.35 23 11V20H21V17H3V20ZM7 14Q5.75 14 4.875 13.125Q4 12.25 4 11Q4 9.75 4.875 8.875Q5.75 8 7 8Q8.25 8 9.125 8.875Q10 9.75 10 11Q10 12.25 9.125 13.125Q8.25 14 7 14Z" />  
        </svg>`,
    },
    HEBERGEMENT_COLLECTIF: {
      color: "#3B44FF",
      icon: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 55" height="${size}" width="${size}" fill="#3B44FF">
          <path d="M1 20V5H3V15H11V7H19Q20.65 7 21.825 8.175Q23 9.35 23 11V20H21V17H3V20ZM7 14Q5.75 14 4.875 13.125Q4 12.25 4 11Q4 9.75 4.875 8.875Q5.75 8 7 8Q8.25 8 9.125 8.875Q10 9.75 10 11Q10 12.25 9.125 13.125Q8.25 14 7 14Z" />
        </svg>`,
    },
    SEJOUR_PACKAGE: {
      color: "#B5838D",
      icon: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 55" height="${size}" width="${size}" fill="#B5838D">
          <path d="M12 17Q9.925 17 8.463 15.537Q7 14.075 7 12Q7 9.925 8.463 8.462Q9.925 7 12 7Q14.075 7 15.538 8.462Q17 9.925 17 12Q17 14.075 15.538 15.537Q14.075 17 12 17ZM2 13Q1.575 13 1.288 12.712Q1 12.425 1 12Q1 11.575 1.288 11.287Q1.575 11 2 11H4Q4.425 11 4.713 11.287Q5 11.575 5 12Q5 12.425 4.713 12.712Q4.425 13 4 13ZM20 13Q19.575 13 19.288 12.712Q19 12.425 19 12Q19 11.575 19.288 11.287Q19.575 11 20 11H22Q22.425 11 22.712 11.287Q23 11.575 23 12Q23 12.425 22.712 12.712Q22.425 13 22 13ZM12 5Q11.575 5 11.288 4.712Q11 4.425 11 4V2Q11 1.575 11.288 1.287Q11.575 1 12 1Q12.425 1 12.713 1.287Q13 1.575 13 2V4Q13 4.425 12.713 4.712Q12.425 5 12 5ZM12 23Q11.575 23 11.288 22.712Q11 22.425 11 22V20Q11 19.575 11.288 19.288Q11.575 19 12 19Q12.425 19 12.713 19.288Q13 19.575 13 20V22Q13 22.425 12.713 22.712Q12.425 23 12 23ZM5.65 7.05 4.575 6Q4.275 5.725 4.287 5.3Q4.3 4.875 4.575 4.575Q4.875 4.275 5.3 4.275Q5.725 4.275 6 4.575L7.05 5.65Q7.325 5.95 7.325 6.35Q7.325 6.75 7.05 7.05Q6.775 7.35 6.363 7.337Q5.95 7.325 5.65 7.05ZM18 19.425 16.95 18.35Q16.675 18.05 16.675 17.638Q16.675 17.225 16.95 16.95Q17.225 16.65 17.638 16.663Q18.05 16.675 18.35 16.95L19.425 18Q19.725 18.275 19.713 18.7Q19.7 19.125 19.425 19.425Q19.125 19.725 18.7 19.725Q18.275 19.725 18 19.425ZM16.95 7.05Q16.65 6.775 16.663 6.362Q16.675 5.95 16.95 5.65L18 4.575Q18.275 4.275 18.7 4.287Q19.125 4.3 19.425 4.575Q19.725 4.875 19.725 5.3Q19.725 5.725 19.425 6L18.35 7.05Q18.05 7.325 17.65 7.325Q17.25 7.325 16.95 7.05ZM4.575 19.425Q4.275 19.125 4.275 18.7Q4.275 18.275 4.575 18L5.65 16.95Q5.95 16.675 6.363 16.675Q6.775 16.675 7.05 16.95Q7.35 17.225 7.338 17.638Q7.325 18.05 7.05 18.35L6 19.425Q5.725 19.725 5.3 19.712Q4.875 19.7 4.575 19.425Z" />
        </svg>`,
    },
    HEBERGEMENT_LOCATIF: {
      color: "#3B44FF",
      icon: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 55" height="${size}" width="${size}" fill="#3B44FF">
          <path d="M1 20V5H3V15H11V7H19Q20.65 7 21.825 8.175Q23 9.35 23 11V20H21V17H3V20ZM7 14Q5.75 14 4.875 13.125Q4 12.25 4 11Q4 9.75 4.875 8.875Q5.75 8 7 8Q8.25 8 9.125 8.875Q10 9.75 10 11Q10 12.25 9.125 13.125Q8.25 14 7 14Z" />
        </svg>`,
    },
    HOTELLERIE_PLEIN_AIR: {
      color: "#3B44FF",
      icon: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 55" height="${size}" width="${size}" fill="#3B44FF">
          <path d="M1 20V5H3V15H11V7H19Q20.65 7 21.825 8.175Q23 9.35 23 11V20H21V17H3V20ZM7 14Q5.75 14 4.875 13.125Q4 12.25 4 11Q4 9.75 4.875 8.875Q5.75 8 7 8Q8.25 8 9.125 8.875Q10 9.75 10 11Q10 12.25 9.125 13.125Q8.25 14 7 14Z" />
        </svg>`,
    },
    ASSOCIATIONS_MUSICALES: {
      color: "#2C798E",
      icon: ` 
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 55" height="${size}" width="${size}" fill="#2C798E">
          <path d="M12.5 15Q13.55 15 14.275 14.275Q15 13.55 15 12.5V7H18V5H14V10.5Q13.675 10.25 13.3 10.125Q12.925 10 12.5 10Q11.45 10 10.725 10.725Q10 11.45 10 12.5Q10 13.55 10.725 14.275Q11.45 15 12.5 15ZM8 18Q7.175 18 6.588 17.413Q6 16.825 6 16V4Q6 3.175 6.588 2.587Q7.175 2 8 2H20Q20.825 2 21.413 2.587Q22 3.175 22 4V16Q22 16.825 21.413 17.413Q20.825 18 20 18ZM4 22Q3.175 22 2.588 21.413Q2 20.825 2 20V6H4V20Q4 20 4 20Q4 20 4 20H18V22Z" />
        </svg>`,
    },
    ASSOCIATIONS_SPORTIVES: {
      color: "#8E2C2C",
      icon: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 55" height="${size}" width="${size}" fill="#8E2C2C">
          <path d="M4.175 18.225Q3.125 16.875 2.562 15.287Q2 13.7 2 12Q2 10.3 2.562 8.712Q3.125 7.125 4.175 5.775Q5.625 6.9 6.45 8.537Q7.275 10.175 7.275 12Q7.275 13.825 6.45 15.463Q5.625 17.1 4.175 18.225ZM12 22Q10.2 22 8.562 21.4Q6.925 20.8 5.575 19.65Q7.3 18.225 8.275 16.238Q9.25 14.25 9.25 12Q9.25 9.75 8.275 7.762Q7.3 5.775 5.575 4.35Q6.925 3.2 8.562 2.6Q10.2 2 12 2Q13.8 2 15.438 2.6Q17.075 3.2 18.425 4.35Q16.7 5.775 15.725 7.762Q14.75 9.75 14.75 12Q14.75 14.25 15.725 16.238Q16.7 18.225 18.425 19.65Q17.075 20.8 15.438 21.4Q13.8 22 12 22ZM19.825 18.225Q18.375 17.1 17.55 15.463Q16.725 13.825 16.725 12Q16.725 10.175 17.55 8.537Q18.375 6.9 19.825 5.775Q20.875 7.125 21.438 8.712Q22 10.3 22 12Q22 13.7 21.438 15.287Q20.875 16.875 19.825 18.225Z" />
        </svg>`,
    },
    ASSOCIATIONS_LOISIRS: {
      color: "#2C8E52",
      icon: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 55" height="${size}" width="${size}" fill="#2C8E52">
          <path d="M4.175 18.225Q3.125 16.875 2.562 15.287Q2 13.7 2 12Q2 10.3 2.562 8.712Q3.125 7.125 4.175 5.775Q5.625 6.9 6.45 8.537Q7.275 10.175 7.275 12Q7.275 13.825 6.45 15.463Q5.625 17.1 4.175 18.225ZM12 22Q10.2 22 8.562 21.4Q6.925 20.8 5.575 19.65Q7.3 18.225 8.275 16.238Q9.25 14.25 9.25 12Q9.25 9.75 8.275 7.762Q7.3 5.775 5.575 4.35Q6.925 3.2 8.562 2.6Q10.2 2 12 2Q13.8 2 15.438 2.6Q17.075 3.2 18.425 4.35Q16.7 5.775 15.725 7.762Q14.75 9.75 14.75 12Q14.75 14.25 15.725 16.238Q16.7 18.225 18.425 19.65Q17.075 20.8 15.438 21.4Q13.8 22 12 22ZM19.825 18.225Q18.375 17.1 17.55 15.463Q16.725 13.825 16.725 12Q16.725 10.175 17.55 8.537Q18.375 6.9 19.825 5.775Q20.875 7.125 21.438 8.712Q22 10.3 22 12Q22 13.7 21.438 15.287Q20.875 16.875 19.825 18.225Z" />
        </svg>`,
    },
    ASSOCIATIONS_CULTURELLES: {
      color: "#615323",
      icon: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 55" height="${size}" width="${size}" fill="#615323">
          <path d="M14 9.9V8.2Q14.825 7.85 15.688 7.675Q16.55 7.5 17.5 7.5Q18.15 7.5 18.775 7.6Q19.4 7.7 20 7.85V9.45Q19.4 9.225 18.788 9.113Q18.175 9 17.5 9Q16.55 9 15.675 9.238Q14.8 9.475 14 9.9ZM14 15.4V13.7Q14.825 13.35 15.688 13.175Q16.55 13 17.5 13Q18.15 13 18.775 13.1Q19.4 13.2 20 13.35V14.95Q19.4 14.725 18.788 14.613Q18.175 14.5 17.5 14.5Q16.55 14.5 15.675 14.725Q14.8 14.95 14 15.4ZM14 12.65V10.95Q14.825 10.6 15.688 10.425Q16.55 10.25 17.5 10.25Q18.15 10.25 18.775 10.35Q19.4 10.45 20 10.6V12.2Q19.4 11.975 18.788 11.863Q18.175 11.75 17.5 11.75Q16.55 11.75 15.675 11.988Q14.8 12.225 14 12.65ZM13 17.05Q14.1 16.525 15.213 16.262Q16.325 16 17.5 16Q18.4 16 19.263 16.15Q20.125 16.3 21 16.6Q21 16.6 21 16.6Q21 16.6 21 16.6V6.7Q21 6.7 21 6.7Q21 6.7 21 6.7Q20.175 6.35 19.288 6.175Q18.4 6 17.5 6Q16.325 6 15.175 6.3Q14.025 6.6 13 7.2ZM12 20Q10.8 19.05 9.4 18.525Q8 18 6.5 18Q5.45 18 4.438 18.275Q3.425 18.55 2.5 19.05Q1.975 19.325 1.488 19.025Q1 18.725 1 18.15V6.1Q1 5.825 1.138 5.575Q1.275 5.325 1.55 5.2Q2.725 4.625 3.963 4.312Q5.2 4 6.5 4Q7.95 4 9.338 4.375Q10.725 4.75 12 5.5Q13.275 4.75 14.663 4.375Q16.05 4 17.5 4Q18.8 4 20.038 4.312Q21.275 4.625 22.45 5.2Q22.725 5.325 22.863 5.575Q23 5.825 23 6.1V18.15Q23 18.725 22.513 19.025Q22.025 19.325 21.5 19.05Q20.575 18.55 19.562 18.275Q18.55 18 17.5 18Q16 18 14.6 18.525Q13.2 19.05 12 20Z" />
        </svg>`,
    },
    RESTAURATION: {
      color: "#00B705",
      icon: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 55" height="${size}" width="${size}" fill="#00B705">
          <path d="M17 22V14H14V7Q14 4.925 15.463 3.463Q16.925 2 19 2V22ZM7 22V12.85Q5.725 12.5 4.863 11.45Q4 10.4 4 9V2H6V9H7V2H9V9H10V2H12V9Q12 10.4 11.137 11.45Q10.275 12.5 9 12.85V22Z" />
        </svg>`,
    },
    DEGUSTATION: {
      color: "#00B705",
      icon: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 55" height="${size}" width="${size}" fill="#00B705">
          <path d="M4 9Q3.575 9 3.288 8.712Q3 8.425 3 8Q3 7.575 3.288 7.287Q3.575 7 4 7H7Q7.825 7 8.412 7.587Q9 8.175 9 9ZM6 18Q7.25 18 8.125 17.125Q9 16.25 9 15Q9 13.75 8.125 12.875Q7.25 12 6 12Q4.75 12 3.875 12.875Q3 13.75 3 15Q3 16.25 3.875 17.125Q4.75 18 6 18ZM19.5 18Q20.125 18 20.562 17.562Q21 17.125 21 16.5Q21 15.875 20.562 15.438Q20.125 15 19.5 15Q18.875 15 18.438 15.438Q18 15.875 18 16.5Q18 17.125 18.438 17.562Q18.875 18 19.5 18ZM6 16.5Q5.375 16.5 4.938 16.062Q4.5 15.625 4.5 15Q4.5 14.375 4.938 13.938Q5.375 13.5 6 13.5Q6.625 13.5 7.062 13.938Q7.5 14.375 7.5 15Q7.5 15.625 7.062 16.062Q6.625 16.5 6 16.5ZM19.5 20Q18.05 20 17.025 18.975Q16 17.95 16 16.5Q16 15.05 17.025 14.025Q18.05 13 19.5 13Q20.95 13 21.975 14.025Q23 15.05 23 16.5Q23 17.95 21.975 18.975Q20.95 20 19.5 20ZM6 20Q3.925 20 2.463 18.538Q1 17.075 1 15Q1 12.925 2.463 11.462Q3.925 10 6 10Q8.075 10 9.538 11.462Q11 12.925 11 15Q11 17.075 9.538 18.538Q8.075 20 6 20ZM11.9 16Q11.95 15.75 11.975 15.512Q12 15.275 12 15Q12 13.875 11.613 12.863Q11.225 11.85 10.45 11H11Q11.825 11 12.413 10.412Q13 9.825 13 9V6.7L11.95 5.65L10.55 7.05L9.8 6.35L13.35 2.8L14.05 3.5L12.65 4.9L13.7 6H20Q20.825 6 21.413 6.588Q22 7.175 22 8V12.75Q21.425 12.4 20.8 12.2Q20.175 12 19.5 12Q17.85 12 16.575 13.087Q15.3 14.175 15.05 16Z" />
        </svg>`,
    },
    ACTIVITE: {
      color: "#FF503B",
      icon: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 55" height="${size}" width="${size}" fill="#FF503B">
          <path d="M4 20Q3.175 20 2.588 19.413Q2 18.825 2 18V14Q2.825 14 3.413 13.412Q4 12.825 4 12Q4 11.175 3.413 10.587Q2.825 10 2 10V6Q2 5.175 2.588 4.588Q3.175 4 4 4H20Q20.825 4 21.413 4.588Q22 5.175 22 6V10Q21.175 10 20.587 10.587Q20 11.175 20 12Q20 12.825 20.587 13.412Q21.175 14 22 14V18Q22 18.825 21.413 19.413Q20.825 20 20 20ZM9.2 16 12 13.9 14.75 16 13.7 12.6 16.5 10.4H13.1L12 7L10.9 10.4H7.5L10.25 12.6Z" />
        </svg>`,
    },
    DOMAINE_SKIABLE: {
      color: "#3efaff",
      icon: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 55" height="${size}" width="${size}" fill="#3efaff">
          <path d="M16.5 6.5Q15.675 6.5 15.088 5.912Q14.5 5.325 14.5 4.5Q14.5 3.675 15.088 3.087Q15.675 2.5 16.5 2.5Q17.325 2.5 17.913 3.087Q18.5 3.675 18.5 4.5Q18.5 5.325 17.913 5.912Q17.325 6.5 16.5 6.5ZM18.5 23Q17.85 23 17.238 22.9Q16.625 22.8 16.05 22.6L2 17.475L2.5 16.05L9.4 18.575L11.125 14.125L7.55 10.4Q7.275 10.1 7.138 9.738Q7 9.375 7 9Q7 8.5 7.25 8.025Q7.5 7.55 8 7.275L11.475 5.275Q11.725 5.125 11.988 5.062Q12.25 5 12.525 5Q13.15 5 13.675 5.362Q14.2 5.725 14.4 6.375L14.725 7.45Q15.05 8.525 15.788 9.35Q16.525 10.175 17.55 10.6L18.075 9L19.5 9.45L18.375 12.9Q16.525 12.6 15.1 11.45Q13.675 10.3 13 8.6L10.475 10.05L13.5 13.5L11.275 19.25L14.375 20.375L16.475 13.95Q16.825 14.075 17.175 14.175Q17.525 14.275 17.9 14.35L15.775 20.9L16.55 21.175Q17 21.325 17.488 21.413Q17.975 21.5 18.5 21.5Q19.15 21.5 19.738 21.375Q20.325 21.25 20.875 21L22 22.125Q21.2 22.55 20.325 22.775Q19.45 23 18.5 23Z" />
        </svg>`,
    },
    FETE_ET_MANIFESTATION: {
      color: "#FCAF05",
      icon: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 55" height="${size}" width="${size}" fill="#FCAF05">
          <path d="M2 22 7 8 16 17ZM14.55 12.55 13.5 11.5 19.1 5.9Q19.9 5.1 21.025 5.1Q22.15 5.1 22.95 5.9L23.55 6.5L22.5 7.55L21.9 6.95Q21.55 6.6 21.025 6.6Q20.5 6.6 20.15 6.95ZM10.55 8.55 9.5 7.5 10.1 6.9Q10.45 6.55 10.45 6.05Q10.45 5.55 10.1 5.2L9.45 4.55L10.5 3.5L11.15 4.15Q11.95 4.95 11.95 6.05Q11.95 7.15 11.15 7.95ZM12.55 10.55 11.5 9.5 15.1 5.9Q15.45 5.55 15.45 5.025Q15.45 4.5 15.1 4.15L13.5 2.55L14.55 1.5L16.15 3.1Q16.95 3.9 16.95 5.025Q16.95 6.15 16.15 6.95ZM16.55 14.55 15.5 13.5 17.1 11.9Q17.9 11.1 19.025 11.1Q20.15 11.1 20.95 11.9L22.55 13.5L21.5 14.55L19.9 12.95Q19.55 12.6 19.025 12.6Q18.5 12.6 18.15 12.95Z" />
        </svg>`,
    },
    COMMERCE_ET_SERVICE: {
      color: "#FF76C7",
      icon: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 55" height="${size}" width="${size}" fill="#FF76C7">
          <path d="M5 21Q4.575 21 4.225 20.738Q3.875 20.475 3.75 20.05L0.95 9.95Q0.825 9.6 1.062 9.3Q1.3 9 1.7 9H6.75L11.15 2.45Q11.275 2.25 11.5 2.125Q11.725 2 11.975 2Q12.225 2 12.45 2.125Q12.675 2.25 12.8 2.45L17.2 9H22.3Q22.7 9 22.938 9.3Q23.175 9.6 23.05 9.95L20.25 20.05Q20.125 20.475 19.775 20.738Q19.425 21 19 21ZM9.175 9H14.8L11.975 4.8ZM12 17Q12.825 17 13.413 16.413Q14 15.825 14 15Q14 14.175 13.413 13.587Q12.825 13 12 13Q11.175 13 10.588 13.587Q10 14.175 10 15Q10 15.825 10.588 16.413Q11.175 17 12 17Z" />
        </svg>`,
    },
    PATRIMOINE_CULTUREL: {
      color: "#AB4F3A",
      icon: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 55" height="${size}" width="${size}" fill="#AB4F3A">
          <path d="M7 22Q4.5 22 2.75 20.25Q1 18.5 1 16V9H13V16Q13 18.5 11.25 20.25Q9.5 22 7 22ZM5 14.5Q5.425 14.5 5.713 14.212Q6 13.925 6 13.5Q6 13.075 5.713 12.787Q5.425 12.5 5 12.5Q4.575 12.5 4.287 12.787Q4 13.075 4 13.5Q4 13.925 4.287 14.212Q4.575 14.5 5 14.5ZM7 17.9Q7.975 17.9 8.738 17.387Q9.5 16.875 9.5 16H4.5Q4.5 16.875 5.263 17.387Q6.025 17.9 7 17.9ZM9 14.5Q9.425 14.5 9.713 14.212Q10 13.925 10 13.5Q10 13.075 9.713 12.787Q9.425 12.5 9 12.5Q8.575 12.5 8.288 12.787Q8 13.075 8 13.5Q8 13.925 8.288 14.212Q8.575 14.5 9 14.5ZM17 15Q16.35 15 15.713 14.863Q15.075 14.725 14.5 14.45V7.5Q14.5 7.5 14.5 7.5Q14.5 7.5 14.5 7.5H11V2H23V9Q23 11.5 21.25 13.25Q19.5 15 17 15ZM15 7.5Q15.425 7.5 15.713 7.213Q16 6.925 16 6.5Q16 6.075 15.713 5.787Q15.425 5.5 15 5.5Q14.575 5.5 14.288 5.787Q14 6.075 14 6.5Q14 6.925 14.288 7.213Q14.575 7.5 15 7.5ZM14.5 10.9H19.5Q19.5 10.025 18.738 9.512Q17.975 9 17 9Q16.15 9 15.325 9.45Q14.5 9.9 14.5 10.9ZM19 7.5Q19.425 7.5 19.712 7.213Q20 6.925 20 6.5Q20 6.075 19.712 5.787Q19.425 5.5 19 5.5Q18.575 5.5 18.288 5.787Q18 6.075 18 6.5Q18 6.925 18.288 7.213Q18.575 7.5 19 7.5Z" />
        </svg>`,
    },
    PATRIMOINE_NATUREL: {
      color: "#AB4F3A",
      icon: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 55" height="${size}" width="${size}" fill="#AB4F3A">
          <path d="M1 18 7 10 11.5 16H19L14 9.35L11.5 12.65L10.25 11L14 6L23 18ZM11.5 12.65 14 9.35 19 16H14.025ZM5 16H9L7 13.325ZM5 16 7 13.325 9 16Z" />
        </svg>`,
    },
    EQUIPEMENT: {
      color: "#575452",
      icon: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 55" height="${size}" width="${size}" fill="#575452">
          <path d="M3 21V7H9V5L12 2L15 5V11H21V21ZM5 19H7V17H5ZM5 15H7V13H5ZM5 11H7V9H5ZM11 19H13V17H11ZM11 15H13V13H11ZM11 11H13V9H11ZM11 7H13V5H11ZM17 19H19V17H17ZM17 15H19V13H17Z" />
        </svg>`,
    },
    STRUCTURE: {
      color: "#575452",
      icon: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 55" height="${size}" width="${size}" fill="#575452">
          <path d="M4 21V19H16V21ZM9.65 16.15 4 10.5 6.1 8.35 11.8 14ZM16 9.8 10.35 4.1 12.5 2 18.15 7.65ZM20.6 20 7.55 6.95 8.95 5.55 22 18.6Z" />
        </svg>`,
    },
    TERRITOIRE: {
      color: "#575452",
      icon: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 55" height="${size}" width="${size}" fill="#575452">
          <path d="M11 9V7H13V9ZM11 13V11H13V13ZM15 13V11H17V13ZM3 21V3H21V21ZM5 19H19V5H5ZM11 17V15H13V17ZM7 13V11H9V13Z" />
        </svg>`,
    },
    ALL: {
      color: "#8844dd",
      icon: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 55" height="${size}" width="${size}" fill="#8844dd">
          <path d="M5.5 17.5Q3.2 17.5 1.6 15.9Q0 14.3 0 12Q0 9.7 1.6 8.1Q3.2 6.5 5.5 6.5Q6.425 6.5 7.275 6.825Q8.125 7.15 8.8 7.75L10.5 9.3L9 10.65L7.45 9.25Q7.05 8.9 6.55 8.7Q6.05 8.5 5.5 8.5Q4.05 8.5 3.025 9.525Q2 10.55 2 12Q2 13.45 3.025 14.475Q4.05 15.5 5.5 15.5Q6.05 15.5 6.55 15.3Q7.05 15.1 7.45 14.75L15.2 7.75Q15.875 7.15 16.725 6.825Q17.575 6.5 18.5 6.5Q20.8 6.5 22.4 8.1Q24 9.7 24 12Q24 14.3 22.4 15.9Q20.8 17.5 18.5 17.5Q17.575 17.5 16.725 17.175Q15.875 16.85 15.2 16.25L13.5 14.7L15 13.35L16.55 14.75Q16.95 15.1 17.45 15.3Q17.95 15.5 18.5 15.5Q19.95 15.5 20.975 14.475Q22 13.45 22 12Q22 10.55 20.975 9.525Q19.95 8.5 18.5 8.5Q17.95 8.5 17.45 8.7Q16.95 8.9 16.55 9.25L8.8 16.25Q8.125 16.85 7.275 17.175Q6.425 17.5 5.5 17.5Z" />
        </svg>`,
    },
    SENTEURS: {
      color: "#8844dd",
      icon: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 55" height="${size}" width="${size}" fill="#8844dd">
          <path d="M5.5 17.5Q3.2 17.5 1.6 15.9Q0 14.3 0 12Q0 9.7 1.6 8.1Q3.2 6.5 5.5 6.5Q6.425 6.5 7.275 6.825Q8.125 7.15 8.8 7.75L10.5 9.3L9 10.65L7.45 9.25Q7.05 8.9 6.55 8.7Q6.05 8.5 5.5 8.5Q4.05 8.5 3.025 9.525Q2 10.55 2 12Q2 13.45 3.025 14.475Q4.05 15.5 5.5 15.5Q6.05 15.5 6.55 15.3Q7.05 15.1 7.45 14.75L15.2 7.75Q15.875 7.15 16.725 6.825Q17.575 6.5 18.5 6.5Q20.8 6.5 22.4 8.1Q24 9.7 24 12Q24 14.3 22.4 15.9Q20.8 17.5 18.5 17.5Q17.575 17.5 16.725 17.175Q15.875 16.85 15.2 16.25L13.5 14.7L15 13.35L16.55 14.75Q16.95 15.1 17.45 15.3Q17.95 15.5 18.5 15.5Q19.95 15.5 20.975 14.475Q22 13.45 22 12Q22 10.55 20.975 9.525Q19.95 8.5 18.5 8.5Q17.95 8.5 17.45 8.7Q16.95 8.9 16.55 9.25L8.8 16.25Q8.125 16.85 7.275 17.175Q6.425 17.5 5.5 17.5Z" />
        </svg>`,
    },
    METIERS_ART: {
      color: "#8844dd",
      icon: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 55" height="${size}" width="${size}" fill="#8844dd">
          <path d="M5.5 17.5Q3.2 17.5 1.6 15.9Q0 14.3 0 12Q0 9.7 1.6 8.1Q3.2 6.5 5.5 6.5Q6.425 6.5 7.275 6.825Q8.125 7.15 8.8 7.75L10.5 9.3L9 10.65L7.45 9.25Q7.05 8.9 6.55 8.7Q6.05 8.5 5.5 8.5Q4.05 8.5 3.025 9.525Q2 10.55 2 12Q2 13.45 3.025 14.475Q4.05 15.5 5.5 15.5Q6.05 15.5 6.55 15.3Q7.05 15.1 7.45 14.75L15.2 7.75Q15.875 7.15 16.725 6.825Q17.575 6.5 18.5 6.5Q20.8 6.5 22.4 8.1Q24 9.7 24 12Q24 14.3 22.4 15.9Q20.8 17.5 18.5 17.5Q17.575 17.5 16.725 17.175Q15.875 16.85 15.2 16.25L13.5 14.7L15 13.35L16.55 14.75Q16.95 15.1 17.45 15.3Q17.95 15.5 18.5 15.5Q19.95 15.5 20.975 14.475Q22 13.45 22 12Q22 10.55 20.975 9.525Q19.95 8.5 18.5 8.5Q17.95 8.5 17.45 8.7Q16.95 8.9 16.55 9.25L8.8 16.25Q8.125 16.85 7.275 17.175Q6.425 17.5 5.5 17.5Z" />
        </svg>`,
    },
    SAVEURS: {
      color: "#8844dd",
      icon: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 55" height="${size}" width="${size}" fill="#8844dd">
          <path d="M5.5 17.5Q3.2 17.5 1.6 15.9Q0 14.3 0 12Q0 9.7 1.6 8.1Q3.2 6.5 5.5 6.5Q6.425 6.5 7.275 6.825Q8.125 7.15 8.8 7.75L10.5 9.3L9 10.65L7.45 9.25Q7.05 8.9 6.55 8.7Q6.05 8.5 5.5 8.5Q4.05 8.5 3.025 9.525Q2 10.55 2 12Q2 13.45 3.025 14.475Q4.05 15.5 5.5 15.5Q6.05 15.5 6.55 15.3Q7.05 15.1 7.45 14.75L15.2 7.75Q15.875 7.15 16.725 6.825Q17.575 6.5 18.5 6.5Q20.8 6.5 22.4 8.1Q24 9.7 24 12Q24 14.3 22.4 15.9Q20.8 17.5 18.5 17.5Q17.575 17.5 16.725 17.175Q15.875 16.85 15.2 16.25L13.5 14.7L15 13.35L16.55 14.75Q16.95 15.1 17.45 15.3Q17.95 15.5 18.5 15.5Q19.95 15.5 20.975 14.475Q22 13.45 22 12Q22 10.55 20.975 9.525Q19.95 8.5 18.5 8.5Q17.95 8.5 17.45 8.7Q16.95 8.9 16.55 9.25L8.8 16.25Q8.125 16.85 7.275 17.175Q6.425 17.5 5.5 17.5Z" />
        </svg>`,
    },
    SERVICES: {
      color: "#8844dd",
      icon: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 55" height="${size}" width="${size}" fill="#8844dd">
          <path d="M5.5 17.5Q3.2 17.5 1.6 15.9Q0 14.3 0 12Q0 9.7 1.6 8.1Q3.2 6.5 5.5 6.5Q6.425 6.5 7.275 6.825Q8.125 7.15 8.8 7.75L10.5 9.3L9 10.65L7.45 9.25Q7.05 8.9 6.55 8.7Q6.05 8.5 5.5 8.5Q4.05 8.5 3.025 9.525Q2 10.55 2 12Q2 13.45 3.025 14.475Q4.05 15.5 5.5 15.5Q6.05 15.5 6.55 15.3Q7.05 15.1 7.45 14.75L15.2 7.75Q15.875 7.15 16.725 6.825Q17.575 6.5 18.5 6.5Q20.8 6.5 22.4 8.1Q24 9.7 24 12Q24 14.3 22.4 15.9Q20.8 17.5 18.5 17.5Q17.575 17.5 16.725 17.175Q15.875 16.85 15.2 16.25L13.5 14.7L15 13.35L16.55 14.75Q16.95 15.1 17.45 15.3Q17.95 15.5 18.5 15.5Q19.95 15.5 20.975 14.475Q22 13.45 22 12Q22 10.55 20.975 9.525Q19.95 8.5 18.5 8.5Q17.95 8.5 17.45 8.7Q16.95 8.9 16.55 9.25L8.8 16.25Q8.125 16.85 7.275 17.175Q6.425 17.5 5.5 17.5Z" />
        </svg>`,
    },
    DEFAULT: { color: THEMES.PRIMARY, icon: "shapes" },
  };
  return catInfo[cat] || catInfo.DEFAULT;
};
