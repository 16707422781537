import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import * as Joi from "joi";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { API_URL, HEADERS, ID_APPLICATION } from "../../constants";
import { UserContext } from "../../contexts/UserContext";
import trad from "../../lang/traduction";

interface FormState {
  firstname: string;
  lastname: string;
  username: string;
  email: string;
  birthdate: string;
  password: string;
  passwordConfirmation: string;
  anonyme: boolean;
  typeCompte: number;
  idApplication: string[];
}

const SignUpForm = () => {
  const { addToast } = useToasts();
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { lang } = useContext(UserContext);
  const navigate = useNavigate();

  // ETAT FORMULAIRE
  const [formState, setFormState] = useState<FormState>({
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    birthdate: "",
    password: "",
    passwordConfirmation: "",
    anonyme: false,
    typeCompte: 3,
    idApplication: [ID_APPLICATION],
  });
  const [error, setError]: any = useState({
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    password: "",
    passwordConfirmation: "",
  });
  // GESTION ET VERIFICATION DES ERREURS
  const newOperatorSchema = Joi.object({
    lastname: Joi.string()
      .min(2)
      .max(30)
      .required()
      .messages({
        "string.empty": `${trad[lang].usercreation.required}`,
        "string.min": `${trad[lang].usercreation.minValidation}`,
        "string.max": `${trad[lang].usercreation.maxValidation}`,
        "any.required": "",
      }),
    firstname: Joi.string()
      .min(2)
      .max(30)
      .required()
      .messages({
        "string.empty": `${trad[lang].usercreation.required}`,
        "string.min": `${trad[lang].usercreation.minValidation}`,
        "string.max": `${trad[lang].usercreation.maxValidation}`,
        "any.required": "",
      }),
    password: Joi.string()
      .required()
      .min(5)
      .messages({
        "string.empty": `${trad[lang].usercreation.required}`,
        "string.min": `${trad[lang].usercreation.passwordMinValidation}`,
        "any.required": "",
      }),
    passwordConfirmation: Joi.any()
      .equal(formState.password)
      .required()
      .label("Confirm password")
      .messages({
        "string.empty": `${trad[lang].usercreation.required}`,
        "any.only": `${trad[lang].usercreation.passwordNotMatches}`,
        "any.required": "",
      }),
    username: Joi.string()
      .min(2)
      .max(30)
      .required()
      .messages({
        "string.empty": `${trad[lang].usercreation.required}`,
        "string.min": `${trad[lang].usercreation.minValidation}`,
        "string.max": `${trad[lang].usercreation.maxValidation}`,
        "any.required": "",
      }),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        "string.empty": `${trad[lang].usercreation.required}`,
        "string.email": `${trad[lang].usercreation.emailValidate}`,
        "any.required": "",
      }),
  }).options({ allowUnknown: true });

  const handleChange = (key: any, value: any) => {
    setFormState({ ...formState, [key]: value });
    const validationResult: any = newOperatorSchema.validate(
      { [key]: value },
      { abortEarly: false }
    );
    if (validationResult.error !== undefined) {
      setError({ ...error, [key]: validationResult.error.message });
    }
    if (validationResult.error.message === "") {
      let errorTmp: any = error;
      delete errorTmp[key];
      setError(errorTmp);
      errorTmp = {};
    }
  };

  // POSTER UN UTILISATEUR
  const onSubmit = async () => {
    setLoading(true);
    try {
      const data = await axios.post(
        `${API_URL}/auth/signup`,
        formState,
        HEADERS
      );
      setFormState({
        firstname: "",
        lastname: "",
        username: "",
        email: "",
        birthdate: "",
        password: "",
        passwordConfirmation: "",
        anonyme: false,
        typeCompte: 3,
        idApplication: [ID_APPLICATION],
      });
      setError({
        firstname: "",
        lastname: "",
        username: "",
        email: "",
        password: "",
        passwordConfirmation: "",
      });

      navigate(`${process.env.PUBLIC_URL}/`);

      addToast(trad[lang].utils.success, {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      console.error(error);
      if (error.response.status === 409)
        addToast("Ce nom d'utilisateur est déjà pris", {
          appearance: "error",
          autoDismiss: true,
        });
      else
        addToast(trad[lang].usercreation.error, {
          appearance: "error",
          autoDismiss: true,
        });
    } finally {
      setLoading(false);
    }
  };

  const validate = () => {
    return Object.keys(error).length === 0;
  };

  useEffect(() => {
    validate();
  }, [formState]);

  return (
    <>
      <div className="flex min-h-full w-full">
        <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-12">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <h2 className="mt-1 text-3xl font-extrabold text-gray-900">
                {trad[lang].usercreation.title}
              </h2>
            </div>

            <div className="mt-8">
              <div className="mt-6">
                <form>
                  {" "}
                  <div className="-mx-3 flex">
                    <div className="mb-2 w-1/2 px-3">
                      <label htmlFor="" className="px-1 text-sm">
                        {trad[lang].usercreation.labelFirstname}
                      </label>
                      <div className="flex">
                        <div className="pointer-events-none z-10 flex w-10 items-center justify-center pl-1 text-center">
                          <i className="mdi mdi-account-outline text-lg text-RICHBLACK"></i>
                        </div>
                        <div className="-ml-10 flex w-full flex-col">
                          <input
                            type="text"
                            required
                            minLength={2}
                            maxLength={30}
                            className="w-full appearance-none rounded-lg border border-SILVER py-2 pr-3 placeholder:text-sm focus:border-transparent focus:outline-none focus:ring-TERTIARY"
                            placeholder={trad[lang].usercreation.firstname}
                            onChange={(e) =>
                              handleChange("firstname", e.target.value)
                            }
                          />
                          <p className="text-left text-xs text-WARNING">
                            {error?.firstname}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mb-2 w-1/2 px-3">
                      <label htmlFor="" className="px-1 text-sm">
                        {trad[lang].usercreation.labelLastName}
                      </label>
                      <div className="flex">
                        <div className="pointer-events-none z-10 flex w-10 items-center justify-center pl-1 text-center">
                          <i className="mdi mdi-account-outline text-lg text-RICHBLACK"></i>
                        </div>
                        <div className="-ml-10 flex w-full flex-col">
                          <input
                            type="text"
                            required
                            minLength={2}
                            maxLength={30}
                            className="w-full appearance-none rounded-lg border border-SILVER py-2 pr-3 placeholder:text-sm focus:border-transparent focus:outline-none focus:ring-TERTIARY"
                            placeholder={trad[lang].usercreation.lastName}
                            onChange={(e) =>
                              handleChange("lastname", e.target.value)
                            }
                          />
                          <p className="text-left text-xs text-WARNING">
                            {error?.lastname}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="-mx-3 flex">
                    <div className="mb-2 w-full px-3">
                      <label htmlFor="" className="px-1 text-sm">
                        {trad[lang].usercreation.labelUserName}
                      </label>
                      <div className="flex">
                        <div className="pointer-events-none z-10 flex w-10 items-center justify-center pl-1 text-center">
                          <i className="mdi mdi-email-outline text-lg text-RICHBLACK"></i>
                        </div>
                        <div className="-ml-10 flex w-full flex-col">
                          <input
                            type="text"
                            required
                            minLength={2}
                            maxLength={30}
                            className="w-full appearance-none rounded-lg border border-SILVER py-2 pr-3 placeholder:text-sm focus:border-transparent focus:outline-none focus:ring-TERTIARY"
                            placeholder={trad[lang].usercreation.userName}
                            onChange={(e) =>
                              handleChange("username", e.target.value)
                            }
                          />
                          <p className="text-left text-xs text-WARNING">
                            {error?.username}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="-mx-3 flex">
                    <div className="mb-2 w-full px-3">
                      <label htmlFor="" className="px-1 text-sm">
                        {trad[lang].usercreation.labelEmail}
                      </label>
                      <div className="flex">
                        <div className="pointer-events-none z-10 flex w-10 items-center justify-center pl-1 text-center">
                          <i className="mdi mdi-email-outline text-lg text-RICHBLACK"></i>
                        </div>
                        <div className="-ml-10 flex w-full flex-col">
                          <input
                            type="email"
                            required
                            className="w-full appearance-none rounded-lg border border-SILVER py-2 pr-3 placeholder:text-sm focus:border-transparent focus:outline-none focus:ring-TERTIARY"
                            placeholder={trad[lang].usercreation.email}
                            onChange={(e) =>
                              handleChange("email", e.target.value)
                            }
                          />
                          <p className="text-left text-xs text-WARNING">
                            {error?.email}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="-mx-3 flex">
                    <div className="mb-2 w-full px-3">
                      <label htmlFor="" className="px-1 text-sm">
                        {trad[lang].usercreation.labelBirthdate}
                      </label>
                      <div className="flex">
                        <div className="pointer-events-none z-10 flex w-10 items-center justify-center pl-1 text-center">
                          <i className="mdi mdi-email-outline text-lg text-RICHBLACK"></i>
                        </div>
                        <input
                          type="date"
                          required
                          className="-ml-10 w-full appearance-none rounded-lg border border-SILVER py-2 pr-3 placeholder:text-sm focus:border-transparent focus:outline-none focus:ring-TERTIARY"
                          onChange={(e) => {
                            handleChange("birthdate", e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="-mx-3 flex">
                    <div className="mb-2 w-full px-3">
                      <label htmlFor="" className="px-1 text-sm">
                        {trad[lang].usercreation.labelPassword}
                      </label>
                      <div className="flex">
                        <div className="pointer-events-none z-10 flex w-10 items-center justify-center pl-1 text-center">
                          <i className="mdi mdi-lock-outline text-lg text-RICHBLACK"></i>
                        </div>
                        <div className="relative -ml-10 flex w-full flex-col">
                          <input
                            type={showPassword ? "text" : "password"}
                            required
                            className="w-full appearance-none rounded-lg border border-SILVER py-2 pr-3 placeholder:text-sm focus:border-transparent focus:outline-none focus:ring-TERTIARY"
                            onChange={(e) =>
                              handleChange("password", e.target.value)
                            }
                            placeholder={trad[lang].usercreation.password}
                          />
                          <p className="text-left text-xs text-WARNING">
                            {error?.password}
                          </p>

                          <button
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            className="absolute right-2 top-[21px] inline-flex -translate-y-1/2 items-center rounded-full bg-ALICEBLUE p-1 text-ALICEBLUE"
                          >
                            {showPassword ? (
                              <EyeIcon className="h-5 w-5 text-TERTIARY" />
                            ) : (
                              <EyeOffIcon className="h-5 w-5 text-TERTIARY" />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="-mx-3 flex">
                    <div className="mb-6 w-full px-3">
                      <label htmlFor="" className="px-1 text-sm">
                        {trad[lang].usercreation.labelPasswordConfirmation}
                      </label>
                      <div className="flex">
                        <div className="pointer-events-none z-10 flex w-10 items-center justify-center pl-1 text-center">
                          <i className="mdi mdi-lock-outline text-lg text-RICHBLACK"></i>
                        </div>
                        <div className="relative -ml-10 flex w-full flex-col">
                          <input
                            type={showPasswordConfirm ? "text" : "password"}
                            required
                            pattern="password"
                            className="w-full appearance-none rounded-lg border border-SILVER py-2 pr-3 placeholder:text-sm focus:border-transparent focus:outline-none focus:ring-TERTIARY"
                            onChange={(e) =>
                              handleChange(
                                "passwordConfirmation",
                                e.target.value
                              )
                            }
                            placeholder={
                              trad[lang].usercreation.passwordConfirmation
                            }
                          />
                          <p className="text-left text-xs text-WARNING">
                            {error?.passwordConfirmation}
                          </p>
                          <button
                            type="button"
                            onClick={() =>
                              setShowPasswordConfirm(!showPasswordConfirm)
                            }
                            className="absolute right-2 top-[21px] inline-flex -translate-y-1/2 items-center rounded-full bg-ALICEBLUE p-1 text-ALICEBLUE"
                          >
                            {showPassword ? (
                              <EyeIcon className="h-5 w-5 text-TERTIARY" />
                            ) : (
                              <EyeOffIcon className="h-5 w-5 text-TERTIARY" />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="-mx-3 flex">
                    <div className="mb-2 w-full px-3">
                      <button
                        type="button"
                        className={`mx-auto block w-full max-w-sm  rounded-md border border-transparent bg-TERTIARY py-2 px-4 text-sm font-medium text-ALICEBLUE shadow-sm hover:bg-BLUE focus:outline-none`}
                        disabled={!validate()}
                        onClick={() => onSubmit()}
                      >
                        {loading ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          trad[lang].usercreation.buttonValidate
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1609861517208-e5b7b4cd4b87?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1935&q=80"
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default SignUpForm;
