import React, { useContext, useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SurveyForm from "./SurveyForm";
import { UserContext } from "../../contexts/UserContext";
import { API_URL, HEADERS, ID_APPLICATION } from "../../constants";
import axios from "axios";
import SmallLoading from "../Loaders/SmallLoading";
import { useToasts } from "react-toast-notifications";
import trad from "../../lang/traduction";
import { SurveyContext } from "./../../contexts/SurveyContext";
import SurveyIdentity from "./SurveyIdentity";
// import data from "./model_questionnaire.json";

const Survey = () => {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const { lang, application } = useContext(UserContext);
  const {
    quizz,
    setQuizz,
    formState,
    isFormStateValid,
    isIdentityValid,
    identity,
  } = useContext(SurveyContext);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [isSent, setIsSent] = useState(false);

  const handleSubmit = async () => {
    try {
      const body = {
        idSurvey: quizz._id,
        answers: formState.flat(1),
        identity: identity,
      };

      const { data } = await axios.post(
        `${API_URL}/survey/answers`,
        body,
        HEADERS
      );
      if (data) {
        addToast(trad[lang].utils.success, {
          appearance: "success",
          autoDismiss: true,
        });
        // navigate(`${process.env.PUBLIC_URL}/`);
        setIsSent(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getQuizz = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${API_URL}/survey/application/${ID_APPLICATION}?last`,
        HEADERS
      );
      setQuizz(data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // const displayPage = () => {
  //   return page === formState.length ? (
  //     <SurveyIdentity />
  //   ) : (
  //     <SurveyForm fsIndex={page} />
  //   );
  // };

  useEffect(() => {
    getQuizz();
  }, []);

  return (
    <Box>
      {loading ? (
        <SmallLoading />
      ) : (
        <div>
          <div className="mb-4 flex flex-col items-center justify-between border-b pb-4 text-3xl font-extrabold">
            <img
              className="w-auto pb-2"
              src={require("../../assets/cmar/cmar_ban.png")}
              alt="Logo"
            />
            <h2 className="text-center">{quizz?.title}</h2>
          </div>

          {isSent ? (
            <p className="text-center text-xl font-bold">
              Merci d'avoir répondu à ce questionnaire
            </p>
          ) : formState.length ? (
            <>
              <SurveyIdentity />
              {/* {displayPage()} */}
              <SurveyForm fsIndex={page} />

              <p style={{ fontSize: 13, marginTop: 20, marginBottom: 20 }}>
                La Chambre de Métiers et de l'Artisanat de la région Provence
                Alpes Côtes d'Azur traite les données recueillies dans ce
                formulaire pour qualifier les artisans pouvant bénéficier du
                développement touristique régional. Pour en savoir plus sur la
                gestion de vos données personnelles et pour exercer vos droits,
                reportez-vous à la{" "}
                <a
                  style={{ color: "blue" }}
                  href="https://www.cmar-paca.fr/article/notre-politique-de-confidentialite"
                  target="_blank"
                  rel="noreferrer"
                >
                  politique de données personnelles de la chambre.
                </a>
              </p>
              <div className="flex justify-end align-middle">
                {/* <Button
                  variant="contained"
                  disabled={page === 0}
                  onClick={() => setPage((prev) => prev - 1)}
                  style={{ marginLeft: 5 }}
                >
                  Précédent
                </Button>

                <Button
                  variant="contained"
                  disabled={
                    page === quizz.questions.length || !isFormStateValid
                  }
                  onClick={() => setPage((prev) => prev + 1)}
                  style={{ marginLeft: 5 }}
                >
                  Suivant
                </Button> */}

                <Button
                  variant="contained"
                  disabled={!isFormStateValid || !isIdentityValid}
                  onClick={() => handleSubmit()}
                  style={{ marginLeft: 5 }}
                >
                  Envoyer
                </Button>
              </div>
              {(!isFormStateValid || !isIdentityValid) && (
                <>
                  <p className="pt-1 text-right text-xs text-red-600">
                    Vous devez répondre à toutes les questions comportant un
                    astérisque.
                  </p>
                  {!isIdentityValid && (
                    <p className="pt-1 text-right text-xs text-red-600">
                      Merci de renseigner vos informations au début du
                      questionnaire (dénomination, code postal et ville) avant
                      de valider l’envoi.
                    </p>
                  )}
                </>
              )}
            </>
          ) : (
            <p className="text-center text-xl font-bold">
              Aucun questionnaire en cours
            </p>
          )}
        </div>
      )}
    </Box>
  );
};

export default Survey;
