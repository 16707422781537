import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link } from "react-router-dom";

function CatName({ name, target }) {
  return (
    <div className="my-2 flex w-fit flex-row items-center gap-4 px-4 text-BLUE lg:w-full lg:px-12">
      <Link
        to={target}
        className="flex flex-row items-center duration-300 hover:text-TERTIARY"
      >
        <h3 className="text-lg font-black">{name}</h3>
        <ChevronRightIcon />
      </Link>
    </div>
  );
}

export default CatName;
