import React, { createContext, useState } from "react";

export const SearchContext: any = createContext(null);

const SearchProvider = ({ children }) => {
  const [results, setResults] = useState([]);

  return (
    <SearchContext.Provider
      value={{
        results,
        setResults,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export default SearchProvider;
