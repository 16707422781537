import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { SurveyContext } from "../../contexts/SurveyContext";
import "./surveyForm.css";

const SurveyForm = ({ fsIndex }) => {
  const { setIsFormStateValid, formState, setFormState } = useContext(
    SurveyContext
  );

  const [surveyState, setSurveyState] = useState(formState[fsIndex]);

  const checkRequired = () => {
    const valid = surveyState
      .filter((x) => x.required)
      .every((y) => y.choices.length);

    setIsFormStateValid(valid);
  };

  const resetChoiceAnswers = (form) => {
    const newSurveyState = [...form];

    for (const q of surveyState) {
      if (
        q.condition &&
        newSurveyState[q.condition.question_index].choices.length &&
        !newSurveyState[q.condition.question_index].choices.includes(
          q.condition.value
        )
      ) {
        newSurveyState[q.id_question] = {
          ...newSurveyState[q.id_question],
          choices: [],
          input: q.type === "MULTIPLE_INPUT" ? {} : "",
        };
      }
    }

    return newSurveyState;
  };

  const handleChange = (question, e, inputIndex = null) => {
    e.preventDefault();
    const newSurveyState = [...surveyState];
    const index = newSurveyState.findIndex(
      (x) => x.id_question === question.id_question
    );

    switch (question.type) {
      case "CHOICE":
        if (e.target.type === "radio") {
          newSurveyState[index].choices = [e.target.value];
        }

        if (e.target.type === "text") {
          newSurveyState[index].input = e.target.value;
        } else {
          newSurveyState[index].input = "";
        }

        const newData = resetChoiceAnswers(newSurveyState);
        setSurveyState(newData);
        break;

      case "CHOICE_INPUT":
        if (!newSurveyState[index].choices.includes(e.target.value)) {
          newSurveyState[index].input = "";
        }

        if (e.target.type === "text") {
          newSurveyState[index].input = e.target.value;
        } else if (e.target.type === "radio") {
          newSurveyState[index].choices = [e.target.value];
        }

        setSurveyState(newSurveyState);
        break;

      case "MULTIPLE_CHOICE":
        if (e.target.type === "checkbox") {
          if (e.target.name === "Non / Aucun") {
            newSurveyState[index].choices = [e.target.name];
          } else {
            if (newSurveyState[index].choices.includes("Non / Aucun")) {
              newSurveyState[index].choices = newSurveyState[
                index
              ].choices.filter((x) => x !== "Non / Aucun");
            }

            newSurveyState[index].choices = newSurveyState[
              index
            ].choices.includes(e.target.name)
              ? newSurveyState[index].choices.filter((x) => x !== e.target.name)
              : [...newSurveyState[index].choices, e.target.name];
          }
        }

        if (
          e.target.type === "text" &&
          (newSurveyState[index].choices.includes("Autre") ||
            newSurveyState[index].choices.includes("Non / Aucun"))
        ) {
          newSurveyState[index].input = e.target.value;
        } else if (
          !newSurveyState[index].choices.includes("Autre") &&
          !newSurveyState[index].choices.includes("Non / Aucun")
        ) {
          newSurveyState[index].input = "";
        }

        setSurveyState([...newSurveyState]);
        break;

      case "MULTIPLE_INPUT":
        newSurveyState[index].input[inputIndex] = e.target.value;
        setSurveyState(newSurveyState);
        break;

      default:
        newSurveyState[index].input = e.target.value;
        setSurveyState(newSurveyState);
        break;
    }
  };

  useEffect(() => {
    checkRequired();
    const newFormState = [...formState];
    newFormState[fsIndex] = surveyState;
    setFormState(newFormState);
  }, [surveyState]);

  useEffect(() => {
    setSurveyState(formState[fsIndex]);
  }, [fsIndex]);

  const CustomForm = (question) => {
    switch (question.type) {
      case "CHOICE_INPUT":
        return (
          <>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
            >
              {question.question_choices.map((choice, index) => (
                <div className="radioInput" key={index}>
                  <FormControlLabel
                    value={choice}
                    control={
                      <Radio checked={question.choices.includes(choice)} />
                    }
                    label={choice}
                    style={{ width: "30%" }}
                    onChange={(e) => handleChange(question, e)}
                  />

                  {question.choices.includes(choice) && (
                    <TextField
                      id={question.question_id}
                      label="Précisez"
                      value={question.input}
                      size="small"
                      fullWidth
                      sx={{ marginBottom: 1 }}
                      onChange={(e) => handleChange(question, e)}
                    />
                  )}
                </div>
              ))}
            </RadioGroup>
          </>
        );

      case "CHOICE":
        return (
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
          >
            {question.question_choices.map((choice, index) => (
              <FormControlLabel
                key={index}
                value={choice}
                control={<Radio checked={question.choices.includes(choice)} />}
                label={choice}
                onChange={(e) => handleChange(question, e)}
              />
            ))}

            {question.other_input && (
              <div className="radioInput">
                <FormControlLabel
                  value="Autre"
                  control={
                    <Radio checked={question.choices.includes("Autre")} />
                  }
                  label="Autre"
                  style={{ width: "30%" }}
                  onChange={(e) => handleChange(question, e)}
                />
                {surveyState[question.id_question]?.choices.includes(
                  "Autre"
                ) && (
                  <TextField
                    id={question.question_id}
                    label="Précisez"
                    size="small"
                    fullWidth
                    value={question.input}
                    sx={{ marginBottom: 1 }}
                    onChange={(e) => handleChange(question, e)}
                  />
                )}
              </div>
            )}

            {question.no_choice && (
              <div className="radioInput">
                <FormControlLabel
                  value="Non"
                  control={<Radio checked={question.choices.includes("Non")} />}
                  label="Non"
                  style={{ width: "30%" }}
                  onChange={(e) => handleChange(question, e)}
                />
                {surveyState[question.question_id]?.choices.includes("Non") && (
                  <TextField
                    id={question.question_id}
                    label="Pourquoi"
                    size="small"
                    fullWidth
                    value={question.input}
                    sx={{ marginBottom: 1 }}
                    onChange={(e) => handleChange(question, e)}
                  />
                )}
              </div>
            )}
          </RadioGroup>
        );

      case "MULTIPLE_INPUT":
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 1,
              marginTop: 1,
            }}
          >
            {question.question_choices.map((choice, index) => (
              <TextField
                key={index}
                id={`${question.question_id}-${index}`}
                label={choice}
                size="small"
                sx={{ marginBottom: 1 }}
                fullWidth
                value={question.input[index] ? question.input[index] : ""}
                onChange={(e) => handleChange(question, e, index)}
              />
            ))}
          </Box>
        );

      case "MULTIPLE_CHOICE":
        return (
          <FormGroup>
            {question.question_choices.map((choice, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    onChange={(e) => handleChange(question, e)}
                    name={choice}
                    checked={question.choices.includes(choice)}
                  />
                }
                label={choice}
              />
            ))}

            {question.other_input && (
              <div className="radioInput">
                <FormControlLabel
                  name="Autre"
                  control={
                    <Checkbox checked={question.choices.includes("Autre")} />
                  }
                  label="Autre"
                  style={{ width: "30%" }}
                  onChange={(e) => handleChange(question, e)}
                />
                {surveyState[question.id_question]?.choices.includes(
                  "Autre"
                ) && (
                  <TextField
                    label="Précisez"
                    size="small"
                    fullWidth
                    value={question.input}
                    sx={{ marginBottom: 1 }}
                    onChange={(e) => handleChange(question, e)}
                  />
                )}
              </div>
            )}

            {question.no_choice && (
              <div className="radioInput">
                <FormControlLabel
                  name="Non / Aucun"
                  control={
                    <Checkbox
                      checked={question.choices.includes("Non / Aucun")}
                    />
                  }
                  label="Non / Aucun"
                  onChange={(e) => handleChange(question, e)}
                  style={{ width: "30%" }}
                />
                {surveyState[question.id_question]?.choices.includes(
                  "Non / Aucun"
                ) && (
                  <TextField
                    label="Pourquoi"
                    size="small"
                    fullWidth
                    value={question.input}
                    sx={{ marginBottom: 1 }}
                    onChange={(e) => handleChange(question, e)}
                  />
                )}
              </div>
            )}
          </FormGroup>
        );

      default:
        return (
          <TextField
            id={question.id}
            size="small"
            fullWidth
            multiline={true}
            rows={2}
            value={question.input}
            sx={{ marginBottom: 1 }}
            onChange={(e) => handleChange(question, e)}
          />
        );
    }
  };

  return (
    <Box sx={{ mb: 2 }}>
      {surveyState &&
        surveyState.length &&
        surveyState.map((question, index) => {
          if (
            question.condition &&
            !surveyState[question.condition?.question_index]?.choices.includes(
              question.condition?.value
            )
          ) {
            return null;
          }

          return (
            <FormControl fullWidth sx={{ marginTop: 5 }} key={index}>
              <FormLabel
                id={`quizz${question.id_question}`}
                className="form-label"
              >
                {question.title} {question.required && "*"}
              </FormLabel>
              {CustomForm(question)}
            </FormControl>
          );
        })}
    </Box>
  );
};

export default SurveyForm;
