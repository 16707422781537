import axios from "axios";
import { Link } from "react-router-dom";
import trad from "../../lang/traduction";
import { Menu, Transition } from "@headlessui/react";
import { UserContext } from "../../contexts/UserContext";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Fragment, useContext, useEffect, useState } from "react";
import { API_URL, HEADERS, ID_APPLICATION } from "../../constants";
import { CatIcon } from "../utils/CatIcon";
import SmallLoading from "../Loaders/SmallLoading";

function CatSelector() {
  const { lang } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  const getCategories = async () => {
    try {
      const { data } = await axios.get(
        `${API_URL}/appPoi/category?idApplication=${ID_APPLICATION}`,
        HEADERS
      );
      if (data) {
        setCategories(data.result);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  if (loading) {
    return <SmallLoading />;
  }

  return (
    <>
      {window.matchMedia("(max-width: 600px)").matches ? (
        <div className="relative">
          <div className="custom flex w-full flex-row gap-4 overflow-x-scroll bg-ALICEBLUE py-8 px-4">
            <span className="absolute left-0 top-0 h-full w-4 bg-gradient-to-r from-ALICEBLUE to-transparent"></span>
            {categories.map((item) => {
              return (
                <span
                  key={item.category_id}
                  className="h-16 min-w-[120px] rounded-md border-2 border-BLUE"
                >
                  <Link
                    to={`${process.env.PUBLIC_URL}/categories/${item.category_id}`}
                    className="flex h-full flex-row items-center justify-center gap-4 px-4 py-2 text-base font-semibold text-BLUE duration-300 hover:bg-PRIMARY hover:bg-opacity-50"
                  >
                    <p className="text-sm lg:text-base">
                      {lang === "fr" ? item.name.fr : item.name.en}
                    </p>
                    <p className="hidden lg:flex">{CatIcon(item.type)}</p>
                  </Link>
                </span>
              );
            })}
            <span className="absolute right-0 top-0 h-full w-4 bg-gradient-to-l from-ALICEBLUE to-transparent"></span>
          </div>
        </div>
      ) : (
        <div className="flex w-full flex-row justify-center gap-4 px-4 py-8">
          {categories.slice(0, 5).map((item) => {
            return (
              <span
                key={item.category_id}
                className="rounded-md border-2 border-BLUE"
              >
                <Link
                  to={`${process.env.PUBLIC_URL}/categories/${item.category_id}`}
                  className="flex h-full flex-row items-center gap-4 px-4 py-2 text-base font-semibold text-BLUE duration-300 hover:bg-PRIMARY hover:bg-opacity-50"
                >
                  <p>{lang === "fr" ? item.name.fr : item.name.en}</p>
                  <p className="hidden lg:flex">{CatIcon(item.type)}</p>
                </Link>
              </span>
            );
          })}

          {/* SELECT */}
          {categories.length > 5 && (
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex w-full justify-center rounded-md border-2 border-BLUE bg-ALICEBLUE px-4 py-2 text-base font-semibold text-BLUE">
                  {trad[lang].catSelector.more}
                  <ChevronDownIcon
                    className="-mr-1 ml-2 h-5 w-5"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-50 mt-2 w-56 origin-top-right rounded-md border-2 border-BLUE bg-ALICEBLUE shadow-lg ring-1 ring-black">
                  <div className="py-1">
                    {categories.slice(5).map((item) => {
                      return (
                        <Menu.Item key={item.category_id}>
                          {({ active }) => (
                            <Link
                              to={`${process.env.PUBLIC_URL}/categories/${item.category_id}`}
                              className="block px-4 py-2 text-sm font-semibold text-BLUE hover:bg-PRIMARY hover:bg-opacity-50"
                            >
                              {lang === "fr" ? item.name.fr : item.name.en}
                            </Link>
                          )}
                        </Menu.Item>
                      );
                    })}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          )}
        </div>
      )}
    </>
  );
}

export default CatSelector;
