import React from "react";
import { THEMES } from "../../constants";

const PoiPopPup = ({ name }) => {
  return (
    <div
      style={{ border: `1.5px solid ${THEMES.ALICEBLUE}` }}
      className="flex items-center justify-center rounded-md bg-ALICEBLUE p-2 shadow-md shadow-gray-500"
    >
      <h2 style={{ color: THEMES.RICHBLACK }} className="text-center font-bold">
        {name}
      </h2>
    </div>
  );
};

export default PoiPopPup;
