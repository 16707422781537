import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import { THEMES } from "../constants";
import { Link } from "react-router-dom";
import { useContext } from "react";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { UserContext } from "../contexts/UserContext";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

function CardPoi({ image, name, alt, target, idPoi }) {
  const { toggleFavorite, isFavorite } = useContext(UserContext);

  // FAVORITES
  const handleChange = (e) => {
    e.stopPropagation();
    toggleFavorite(idPoi);
  };

  return (
    <div className="flex flex-col">
      <div className="inline-block w-[150px] lg:w-[200px]">
        <Link to={target} className="snap-center snap-always">
          <img
            src={image}
            alt={alt}
            className="h-[173px] w-full overflow-hidden rounded-md object-cover shadow-lg shadow-zinc-500 lg:h-[230px]"
            style={{ imageRendering: "pixelated" }}
          />
        </Link>
      </div>
      <div className="w-[130px] p-2 lg:w-[160px]">
        <p className="truncate text-sm font-bold text-RICHBLACK">{name}</p>
      </div>
      <span className="absolute bottom-1 right-0 flex justify-end gap-1">
        <button
          aria-label="add to favorites"
          className="rounded-md p-1 text-WARNING duration-300"
          onClick={(e) => handleChange(e)}
        >
          {isFavorite(idPoi) ? <FavoriteIcon /> : <FavoriteBorderIcon />}
        </button>
      </span>
    </div>
  );
}

export default CardPoi;
