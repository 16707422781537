import { Box, Button, TextField } from "@mui/material";
import axios from "axios";
import { useContext, useState } from "react";
import { API_URL } from "../../constants";
import { UserContext } from "../../contexts/UserContext";
import trad from "../../lang/traduction";
import { useToasts } from "react-toast-notifications";

// const style = {
//   position: "absolute" as "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
//   boxShadow: 24,
//   p: 4,
//   borderRadius: 4,
// };

const ModalDeleteWishlist = ({ id, handleCloseDelete }) => {
  const [deleteWishlist, setDeleteWishlist] = useState<any>();
  const { authHeaders, getWishlists, lang } = useContext(UserContext);
  const { addToast } = useToasts();

  // Delete WISHLIST
  const handleDeleteWishlist = async () => {
    try {
      const { data } = await axios.delete(
        `${API_URL}/user/wishlist/${id}`,
        authHeaders
      );
      setDeleteWishlist(data);
      getWishlists();
      addToast(trad["fr"].wishlist.wishlistDelete, {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleCloseOnDelete = () => {
    handleCloseDelete();
    handleDeleteWishlist();
  };

  return (
    <div className="absolute bottom-0 flex h-1/3 w-full flex-col items-center rounded-md bg-ALICEBLUE p-4 lg:top-1/2 lg:left-1/2 lg:h-fit lg:w-[400px] lg:-translate-x-1/2 lg:-translate-y-1/2">
      <h2>{trad[lang].wishlist.deleteWishlist}</h2>
      <div className="mt-4 flex flex-row gap-4">
        <button
          className="w-16 rounded-md border border-BLUE bg-BLUE py-2 px-4 text-ALICEBLUE duration-300 hover:border-red-600"
          onClick={handleCloseOnDelete}
        >
          {trad[lang].wishlist.yes}
        </button>
        <button
          className="w-16 rounded-md border border-BLUE bg-ALICEBLUE py-2 px-4 text-BLUE duration-300 hover:border-RICHBLACK hover:bg-PRIMARY hover:text-RICHBLACK"
          onClick={handleCloseDelete}
        >
          {trad[lang].wishlist.no}
        </button>
      </div>
    </div>
  );
};

export default ModalDeleteWishlist;
