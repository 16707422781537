import axios from "axios";
import dayjs from "dayjs";
import trad from "../lang/traduction";
import { useParams } from "react-router-dom";
import Loading from "../components/Loaders/Loading";
import { Menu, Transition } from "@headlessui/react";
import { UserContext } from "../contexts/UserContext";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { API_URL, HEADERS, ID_APPLICATION } from "../constants";
import { MapContainer, Polyline, TileLayer } from "react-leaflet";

const ChallengeDetails = () => {
  const { id } = useParams();
  const { lang } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [sports, setSports] = useState<any>({});
  const [challenge, setChallenge] = useState<any>({});
  const [classement, setClassement] = useState<any>([]);
  const [idSport, setIdSport] = useState<any>(13);
  const [selectedSport, setSelectedSport] = useState<any>();

  const getChallenge = async () => {
    try {
      const { data } = await axios.get(
        `${API_URL}/sport/challenge/${id}?positions=true`,
        HEADERS
      );
      if (data) {
        setChallenge(data);
      }
      const { data: sportData } = await axios.get(
        `${API_URL}/sport/application/${ID_APPLICATION}/sports`,
        HEADERS
      );
      if (sportData) {
        setSports(sportData);
      }
      const { data: classementData } = await axios.get(
        `${API_URL}/sport/challenge/${id}/classement?idSport=${idSport}`,
        HEADERS
      );
      if (classementData) {
        setClassement(classementData);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getChallenge();
  }, []);

  const _map: any = useRef();

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="flex w-full flex-col pb-16 lg:px-24">
      <h3 className="mx-4 mt-8 mb-5 text-center text-3xl font-bold text-RICHBLACK">
        {trad[lang].challenges.detailsTitle}
      </h3>
      <div className="mt-4 flex h-2/3 w-full flex-col items-center lg:mt-8 lg:flex-row lg:justify-center lg:gap-8">
        <div className="flex h-fit w-full justify-center lg:w-[900px]">
          <div className="-lg h-[400px] w-full lg:h-[500px] lg:w-[900px] lg:border-2 lg:border-BLUE">
            <MapContainer
              ref={(ref) => {
                _map.current = ref;
              }}
              center={[
                challenge?.gpxPositions
                  .map((item) => parseFloat(item.latitude))
                  .reduce((p, c) => c + p, 0) / challenge?.gpxPositions.length,
                challenge?.gpxPositions
                  .map((item) => parseFloat(item.longitude))
                  .reduce((p, c) => c + p, 0) / challenge?.gpxPositions.length,
              ]}
              zoom={13}
              scrollWheelZoom={false}
              zoomControl={true}
              dragging={true}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <Polyline
                pathOptions={{ color: challenge?.couleur, opacity: 1 }}
                positions={challenge?.gpxPositions.map((item) => [
                  item.latitude,
                  item.longitude,
                ])}
              />
            </MapContainer>
          </div>
        </div>
        <div className="h-full w-full lg:w-1/3">
          <div className="bg-PRIMARY bg-opacity-40 p-4 lg:rounded-md lg:border lg:border-BLUE">
            <p className="mb-5 text-center text-lg font-semibold">
              {challenge?.libelle}
            </p>
            {challenge.dateDebut !== null ? (
              <span className="flex flex-row justify-center gap-5">
                <span className="flex flex-row gap-2">
                  <p className="text-base">{trad[lang].challenges.start}: </p>
                  <p className="text-base">
                    {dayjs(challenge?.dateDebut).format("DD/MM/YYYY")}
                  </p>
                </span>
                <span className="flex flex-row gap-2">
                  <p className="text-base">{trad[lang].challenges.end}: </p>
                  <p className="text-base">
                    {dayjs(challenge?.dateFin).format("DD/MM/YYYY")}
                  </p>
                </span>
              </span>
            ) : (
              <p className="mb-3 text-center text-base italic">
                {trad[lang].challenges.noDate}
              </p>
            )}
            <span className="flex flex-row justify-center">
              <p className="text-base">
                {challenge.distanceKm}km -{" "}
                {Math.round(challenge.denivelePositif)}m D+ /{" "}
                {Math.round(challenge.deniveleNegatif)}m D-
              </p>
            </span>
          </div>
          {/* SELECT */}
          <div className="my-5">
            <div className="px-4 sm:px-6 lg:px-0">
              <div className="sm:flex sm:items-center">
                <div className="mx-auto flex w-full flex-row items-center justify-between sm:flex-auto">
                  <h1 className="text-xl font-semibold text-gray-900">
                    {trad[lang].challenges.ranking}
                  </h1>
                  <Menu as="div" className="relative my-8 inline-block">
                    {({ open }) => (
                      <>
                        <div>
                          <Menu.Button className="inline-flex w-full justify-center rounded-md border bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2">
                            {selectedSport === undefined
                              ? trad[lang].challenges.select
                              : selectedSport?.libelle}
                            <ChevronDownIcon
                              className="-mr-1 ml-2 h-5 w-5"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            className="absolute right-0 z-50 mt-2 w-56 origin-top-right rounded-md border-2 border-BLUE bg-ALICEBLUE shadow-lg ring-1 ring-black"
                          >
                            <div className="py-1">
                              {sports.map((item) => (
                                <Menu.Item key={item.idSport}>
                                  <span
                                    className="block cursor-pointer px-4 py-2 text-base font-semibold text-BLUE hover:bg-PRIMARY hover:bg-opacity-50"
                                    onClick={(e) => {
                                      setSelectedSport(item);
                                      setIdSport(item.idSport);
                                    }}
                                  >
                                    {item.libelle}
                                  </span>
                                </Menu.Item>
                              ))}
                            </div>
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                </div>
              </div>
              {classement.lenght < 0 ? (
                <div className="flex w-full flex-col ">
                  <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <table className="w-full divide-y divide-gray-300">
                          <thead className="bg-PRIMARY bg-opacity-40">
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 px-2 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                {trad[lang].challenges.rank}
                              </th>
                              <th
                                scope="col"
                                className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                {trad[lang].challenges.name}
                              </th>
                              <th
                                scope="col"
                                className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                {trad[lang].challenges.time}
                              </th>
                              <th
                                scope="col"
                                className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                {trad[lang].challenges.date}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {classement.map((item, index) => (
                              <tr key={index}>
                                <td className="whitespace-nowrap py-4 px-2 text-center text-sm font-medium text-gray-900 sm:pl-6">
                                  {item.classement}
                                </td>
                                <td className="whitespace-nowrap px-2 py-4 text-sm text-gray-500">
                                  {item.prenom} {item.nom}
                                </td>
                                <td className="whitespace-nowrap px-2 py-4 text-sm text-gray-500">
                                  {item.tempsFormat}
                                </td>
                                <td className="whitespace-nowrap px-2 py-4 text-sm text-gray-500">
                                  {dayjs(item?.date).format("DD/MM/YYYY")}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <p className="w-full text-center italic">
                  {trad[lang].challenges.noRanking}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChallengeDetails;
