import "./index.css";
import App from "./App";
import React from "react";
import ReactDOM from "react-dom/client";
import SearchProvider from "./contexts/SearchContext";
import { ToastProvider } from "react-toast-notifications";
import UserProvider from "./contexts/UserContext";
import MapProvider from "./contexts/MapContext";
import SurveyProvider from "./contexts/SurveyContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ToastProvider placement="top-right">
      <UserProvider>
        <SearchProvider>
          <MapProvider>
            <SurveyProvider>
              <App />
            </SurveyProvider>
          </MapProvider>
        </SearchProvider>
      </UserProvider>
    </ToastProvider>
  </React.StrictMode>
);
