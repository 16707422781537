import axios from "axios";
import trad from "../lang/traduction";
import NoPic from "../assets/no-image.png";
import CardPoi from "../components/CardPoi";
import { useParams } from "react-router-dom";
import NoPicFr from "../assets/pas-image.png";
import Loading from "../components/Loaders/Loading";
import { UserContext } from "../contexts/UserContext";
import { useContext, useEffect, useState } from "react";
import CatSelector from "../components/Categories/CatSelector";
import { API_URL, HEADERS, ID_APPLICATION } from "../constants";

const Categories = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const { postFavorite, lang } = useContext(UserContext);
  const [category, setCategory] = useState<any[]>([]);

  const getCategory = async () => {
    try {
      const { data } = await axios.get(
        `${API_URL}/appPoi/poi?idApplication=${ID_APPLICATION}&categoryId=${id}`,
        HEADERS
      );
      if (data) {
        setCategory(data.result);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCategory();
  }, [id]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="flex w-full flex-col items-center">
      <h3 className="my-8 mx-4 text-center text-3xl font-bold text-RICHBLACK">
        {category?.length > 0
          ? lang === "fr"
            ? category[0].categories[0].name.fr
            : category[0].categories[0].name.en
          : null}
      </h3>
      <span className="w-full">
        <CatSelector />
      </span>
      <div className="flex flex-wrap justify-center gap-3 md:w-4/5">
        {category?.length > 0 ? (
          category.map((item) => {
            return (
              <div className="item" key={item._id}>
                <CardPoi
                  image={
                    lang === "fr"
                      ? item?.medias[0]
                        ? item.medias[0].url
                        : NoPicFr
                      : item?.medias[0]
                      ? item.medias[0].url
                      : NoPic
                  }
                  name={item.name}
                  target={`${process.env.PUBLIC_URL}/poidetails/${item._id}`}
                  idPoi={item._id}
                  alt={item.name}
                />
              </div>
            );
          })
        ) : (
          <div className="text-lg font-semibold italic text-RICHBLACK">
            {trad[lang].categories.noResults}
          </div>
        )}
      </div>
    </div>
  );
};

export default Categories;
