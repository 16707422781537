import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ResetPasswordType } from "../../types/auth";
import trad from "../../lang/traduction";
import { useToasts } from "react-toast-notifications";
import * as EmailValidator from "email-validator";
import { UserContext } from "../../contexts/UserContext";
import { API_URL } from "../../constants";
import {
  Button,
  CssBaseline,
  TextField,
  Container,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  CircularProgress,
  Grid,
  Modal,
} from "@mui/material";
import useStyles from "../../styles/AuthStyles";

function ForgotPasswordForm() {
  const { addToast } = useToasts();
  const [email, setEmail] = useState("");
  const [pseudo, setPseudo]: any = useState("");
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const { authHeaders, lang } = useContext(UserContext);
  const classes = useStyles();

  const handleOpen = async () => {
    try {
      setOpen(true);
      if (EmailValidator.validate(email)) {
        const { data } = await axios.post(
          `${API_URL}/auth/findAllUserByMail`,
          {
            email: email,
          },
          authHeaders
        );
        setUsers(data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      const formData: ResetPasswordType = {
        email: email,
        pseudo: pseudo,
      };
      const { data } = await axios.post(
        `${API_URL}/auth/changePassword`,
        formData,
        authHeaders
      );
      setLoading(false);
      addToast(trad[lang].utils.success, {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (err) {
      setLoading(false);
      addToast(trad[lang].resetPassword.invalid, {
        appearance: "error",
        autoDismiss: true,
      });
      console.error(err);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validate = () => {
    return EmailValidator.validate(email);
  };

  const body = (
    <div className={classes.modalContent}>
      {users.length > 0 ? (
        <div>
          <h3 id="simple-modal-title">
            {trad[lang].resetPassword.selectPseudo}
          </h3>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">
              {trad[lang].resetPassword.labelPseudo}
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={pseudo}
              label={trad[lang].resetPassword.labelPseudo}
              onChange={(e) => {
                setPseudo(e.target.value);
                handleClose();
              }}
            >
              {users.map((user: any) => (
                <MenuItem key={user.IDPersonne} value={user.Pseudo}>
                  {user.Pseudo}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Grid style={{ margin: 10 }}>
            <Button
              className="bottom-button"
              size="small"
              variant="outlined"
              color="primary"
              style={{ marginRight: "5px" }}
              onClick={() => {
                setPseudo("");
                setOpen(false);
              }}
            >
              {trad[lang].utils.cancel}
            </Button>
          </Grid>
        </div>
      ) : (
        <div>
          <h3>{trad[lang].resetPassword.noUsers}</h3>
          <p>{trad[lang].resetPassword.retry}</p>
          <Grid style={{ margin: 10 }}>
            <Button
              className="bottom-button"
              size="small"
              variant="outlined"
              color="primary"
              style={{ marginRight: "5px" }}
              onClick={() => {
                setOpen(false);
              }}
            >
              {trad[lang].utils.cancel}
            </Button>
          </Grid>
        </div>
      )}
    </div>
  );

  return (
    <div className="container mx-auto">
      <div className="my-12 flex justify-center md:px-6">
        {/* Row */}
        <div className="flex w-full lg:w-11/12 xl:w-3/4">
          {/* Col */}
          <div
            className="hidden h-auto w-full bg-gray-400 bg-cover lg:block lg:w-1/2"
            style={{
              backgroundImage: `url(
                "https://images.unsplash.com/photo-1525785967371-87ba44b3e6cf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1173&q=80"
              )`,
            }}
          ></div>
          {/* Col */}
          <div className="w-full rounded-lg bg-ALICEBLUE md:p-5 lg:w-1/2 lg:rounded-l-none">
            <div className="mb-4 px-8 text-center">
              <h3 className="mb-2 pt-4 text-2xl">
                {trad[lang].resetPassword.title}
              </h3>
              <p className="mb-4 text-sm text-RICHBLACK">
                {trad[lang].resetPassword.description}
              </p>
            </div>
            <form className="mb-4 rounded bg-ALICEBLUE pt-6 pb-8 md:px-8">
              <div className="mb-4">
                <label
                  className="mb-2 block text-sm text-RICHBLACK"
                  htmlFor="email"
                >
                  {trad[lang].resetPassword.labelEmail}
                </label>
                <input
                  className="w-full appearance-none rounded border px-3 py-2 text-sm leading-tight text-RICHBLACK shadow focus:border-TERTIARY focus:ring-TERTIARY"
                  id="email"
                  type="email"
                  placeholder={trad[lang].resetPassword.placeholderEmail}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label
                  className="mb-2 block text-sm text-RICHBLACK"
                  htmlFor="login"
                >
                  {trad[lang].resetPassword.labelPseudo}
                </label>
                <input
                  className="w-full appearance-none rounded border px-3 py-2 text-sm leading-tight text-RICHBLACK shadow focus:border-TERTIARY focus:ring-TERTIARY"
                  id="login"
                  type="text"
                  placeholder={trad[lang].resetPassword.placeholderPseudo}
                  onChange={(e) => setPseudo(e.target.value)}
                />
              </div>
              <div className="mb-6 flex flex-row gap-3 text-center">
                <button
                  className="w-fit cursor-pointer rounded-md border-2 border-TERTIARY px-4 py-2 text-sm font-medium text-TERTIARY duration-300 hover:border-white hover:bg-BLUE hover:text-ALICEBLUE"
                  type="button"
                  onClick={handleOpen}
                  disabled={!EmailValidator.validate(email)}
                >
                  {trad[lang].resetPassword.forgotPseudo}
                </button>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  {body}
                </Modal>
                <div>
                  <button
                    className="flex w-full cursor-pointer justify-center rounded-md border border-transparent bg-TERTIARY py-2 px-4 text-sm font-medium text-ALICEBLUE shadow-sm duration-300 hover:bg-BLUE"
                    type="button"
                    onClick={onSubmit}
                    disabled={!validate()}
                  >
                    {loading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      trad[lang].resetPassword.resetPassword
                    )}
                  </button>
                </div>
              </div>
              <hr className="mb-6 border-t" />
              <div className="text-center">
                <Link
                  className="inline-block align-baseline text-sm text-TERTIARY hover:text-BLUE"
                  to={`${process.env.PUBLIC_URL}/sign-up`}
                >
                  {trad[lang].resetPassword.createAccount}
                </Link>
              </div>
              <div className="text-center">
                <Link
                  className="mt-4 inline-block align-baseline text-sm text-TERTIARY hover:text-BLUE"
                  to={`${process.env.PUBLIC_URL}/sign-in`}
                >
                  {trad[lang].resetPassword.alreadyHave}
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordForm;
