import { createContext, useEffect, useState } from "react";
import { IAnswer } from "../types/quizz";

export const SurveyContext: any = createContext(null);

const SurveyProvider = ({ children }) => {
  const [quizz, setQuizz] = useState({
    _id: "",
    answers: 0,
    questions: [],
    title: "",
    identity_form: {
      postalCode: false,
      organization: false,
    },
    idApplication: 0,
  });
  const [formState, setFormState] = useState<IAnswer[][]>([]);
  const [identity, setIdentity] = useState({});
  const [isFormStateValid, setIsFormStateValid] = useState(false);
  const [isIdentityValid, setIsIdentityValid] = useState(false);

  useEffect(() => {
    if (quizz && quizz.questions.length) {
      const newQuizz = [];
      for (const q of quizz.questions) {
        const answersArray = [];
        for (const question of q) {
          answersArray.push({
            title: question.title,
            question_choices: question.choices,
            id_question: question.index,
            choices: [],
            input: question.type === "MULTIPLE_INPUT" ? {} : "",
            required: question.required,
            type: question.type,
            other_input: question.other_input,
            no_choice: question.no_choice,
            condition: question.condition,
          });
        }
        newQuizz.push(answersArray);
      }
      setFormState(newQuizz);

      let newIdForm = {};
      for (const elt of Object.keys(quizz.identity_form)) {
        if (elt) {
          newIdForm = { ...newIdForm, [elt]: "" };
        }
      }
      setIdentity(newIdForm);
    }
  }, [quizz]);

  return (
    <SurveyContext.Provider
      value={{
        quizz,
        setQuizz,
        formState,
        setFormState,
        isFormStateValid,
        setIsFormStateValid,
        identity,
        setIdentity,
        isIdentityValid,
        setIsIdentityValid,
      }}
    >
      {children}
    </SurveyContext.Provider>
  );
};

export default SurveyProvider;
