import React from "react";
import SignInForm from "../../components/auth/SignInForm";

const SignIn = () => {
  return (
    <div className="flex h-[calc(100vh-64px)] w-full flex-row items-center justify-center">
      <SignInForm />
    </div>
  );
};

export default SignIn;
