import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CardPoi from "../components/CardPoi";
import { API_URL } from "../constants";
import { UserContext } from "../contexts/UserContext";
import NoPic from "../assets/no-image.png";
import trad from "../lang/traduction";
import DeleteIcon from "@mui/icons-material/Delete";

const Wishlist = () => {
  const [poiWishlist, setPoiWishlist] = useState<any>([]);
  const [deletePoiWishlist, setDeletePoiWishlist] = useState<any>();
  const { authHeaders, lang } = useContext(UserContext);
  const { id } = useParams();

  const getWishlistsById = async () => {
    try {
      const { data } = await axios.get(
        `${API_URL}/user/wishlist/${id}`,
        authHeaders
      );
      if (data) {
        setPoiWishlist(data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleDeletePoiWishlist = async (idPoi) => {
    try {
      const { data } = await axios.delete(
        `${API_URL}/user/wishlist/${id}/poi/${idPoi}`,
        authHeaders
      );
      setDeletePoiWishlist(data);
      getWishlistsById();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getWishlistsById();
  }, []);

  return (
    <div className="flex w-full flex-col items-center">
      <h3 className="my-8 mx-4 text-center text-3xl font-bold text-RICHBLACK">
        {poiWishlist.title}
      </h3>
      <div className="flex flex-wrap justify-center gap-3 md:w-4/5">
        {poiWishlist.poiList?.length > 0 ? (
          poiWishlist.poiList?.map((item) => {
            return (
              <div className="item" key={item._id}>
                <CardPoi
                  image={item?.medias[0] ? item.medias[0].url : NoPic}
                  name={item.name}
                  target={`${process.env.PUBLIC_URL}/poidetails/${item._id}`}
                  idPoi={item._id}
                  alt={item.name}
                />
                <span className="absolute top-1 right-1 flex flex-row items-center justify-center gap-4">
                  <button
                    className="flex items-center justify-center rounded-md bg-red-600 py-1 px-2 text-ALICEBLUE"
                    onClick={() => handleDeletePoiWishlist(item._id)}
                  >
                    <DeleteIcon />
                  </button>
                </span>
              </div>
            );
          })
        ) : (
          <div>{trad[lang].wishlist.noPoiInWishlist}</div>
        )}
      </div>
    </div>
  );
};

export default Wishlist;
