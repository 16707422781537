import React from "react";
import ForgotPasswordForm from "../../components/auth/ForgotPasswordForm";

const ForgotPassword = () => {
  return (
    <div className="flex h-[calc(100vh-64px)] w-full flex-row items-center justify-center px-4">
      <ForgotPasswordForm />
    </div>
  );
};

export default ForgotPassword;
