// import tradRes from "./lang/traduction.json";

// ENVIRONNEMENT
export const API_URL = process.env.REACT_APP_API_URL;
export const VERSION = process.env.REACT_APP_VERSION;
export const APP_ID = process.env.REACT_APP_ID;
export const APP_PASSWORD = process.env.REACT_APP_PASSWORD;

export const HEADERS = {
  headers: {
    appId: APP_ID,
    appPassword: APP_PASSWORD,
  },
};

export const ID_APPLICATION = "271";

export const survey_app = ["271", "242"];

// THEMES
export const THEMES = {
  BLUE: "#123456",
  PRIMARY: "#A4BEF3",
  SECONDARY: "#dab15b",
  TERTIARY: "#3d637f",
  WARNING: "#F45B69",
  GOLD: "#F4E76E",
  SILVER: "#AAB5C0",
  ALICEBLUE: "#F2F7FC",
  RICHBLACK: "#03080D",

  // TRACKS COLORS
  GREEN_TRACK: "#62EB90",
  BLUE_TRACK: "#62BAEB",
  RED_TRACK: "#EB7262",
  BLACK_TRACK: "#353535",
};
