import { useContext } from "react";
import trad from "../../lang/traduction";
import { UserContext } from "../../contexts/UserContext";

const ModalCreateWishlist = ({ handleClose }) => {
  const { postWishlist, setWishlists, getWishlists, lang } = useContext(
    UserContext
  );

  // POSTER UNE WISHLIST

  const handleCloseOnPost = () => {
    postWishlist();
    handleClose();
    getWishlists();
  };

  return (
    <div className="absolute bottom-0 z-[9000] flex h-[30%] w-full flex-col items-center rounded-md bg-ALICEBLUE p-4 lg:top-1/2 lg:left-1/2 lg:h-fit lg:w-[400px] lg:-translate-x-1/2 lg:-translate-y-1/2">
      <label
        htmlFor="createWishlist"
        className="ml-2 block text-base text-RICHBLACK"
      >
        {trad[lang].wishlist.createWishlist}
      </label>
      <input
        id="createWishlist"
        type="text"
        placeholder={trad[lang].wishlist.nameWishlist}
        className="my-4 w-4/5 rounded border-SILVER bg-ALICEBLUE text-RICHBLACK focus:border-BLUE focus:ring-TERTIARY"
        onChange={(e: any) =>
          setWishlists((old: any) => {
            return { ...old, title: e.target.value };
          })
        }
      />

      <button
        onClick={handleCloseOnPost}
        className="flex w-fit justify-center rounded-md border border-transparent bg-BLUE py-2 px-4 text-sm font-medium text-ALICEBLUE shadow-sm duration-300 hover:bg-PRIMARY hover:text-RICHBLACK focus:outline-none focus:ring-2 focus:ring-TERTIARY focus:ring-offset-2"
      >
        {trad[lang].wishlist.validate}
      </button>
    </div>
  );
};

export default ModalCreateWishlist;
