function NewSlider({ content, sliderId }) {
  //FONCTION POUR SLIDER A GAUCHE
  const slideToLeft = () => {
    const slider = document.getElementById(`slider-${sliderId}`);
    slider.scrollLeft = slider.scrollLeft - 200;
  };
  //FONCTION POUR SLIDER A DROITE
  const slideToRight = () => {
    const slider = document.getElementById(`slider-${sliderId}`);
    slider.scrollLeft = slider.scrollLeft + 200;
  };

  return (
    <div
      id="wrapper"
      className="relative flex h-fit w-full items-center justify-center rounded-md shadow-gray-400 lg:w-[calc(100%-96px)]"
    >
      <div
        id="leftBtn"
        onClick={slideToLeft}
        className="absolute -left-12 hidden h-fit w-fit cursor-pointer rounded-full border-2 border-BLUE bg-ALICEBLUE p-1 text-BLUE opacity-50 duration-300 hover:opacity-100 md:flex"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M11 17l-5-5m0 0l5-5m-5 5h12"
          />
        </svg>
      </div>

      <div
        id={`slider-${sliderId}`}
        className="custom flex h-full w-full snap-x snap-proximity gap-3 overflow-x-scroll scroll-smooth whitespace-nowrap"
      >
        {content}
      </div>

      <div
        id="rightBtn"
        onClick={slideToRight}
        className="absolute -right-12 hidden h-fit w-fit cursor-pointer rounded-full border-2 border-BLUE bg-ALICEBLUE p-1 text-BLUE opacity-50 duration-300 hover:opacity-100 md:flex"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M13 7l5 5m0 0l-5 5m5-5H6"
          />
        </svg>
      </div>
    </div>
  );
}

export default NewSlider;
