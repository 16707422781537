import trad from "../../lang/traduction";
import Loader from "../../assets/Rolling-1s-200px.png";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";

function Loading() {
  const { lang } = useContext(UserContext);

  return (
    <div className="flex h-[calc(100vh-64px)] flex-col items-center justify-center">
      <img
        className="
        my-3
        h-20
        w-20
        animate-spin
      "
        alt="Loader"
        src={Loader}
      />
      <span className="font-bold text-BLUE">{trad[lang].loading.title}</span>
    </div>
  );
}

export default Loading;
